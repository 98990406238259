/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import MultiSelect from "../MultiSelect";
import { Platform, platformToString } from "../../Constants/Type";
import Button from "../Button";
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';

interface PlatformMultiSelectProps {
  initialValues?: Platform[];
  onSave: (values: Platform[]) => void;
  name: string;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const buttonStyles = css`
  align-self: flex-end;
`;

const PlatformMultiSelect: React.FC<PlatformMultiSelectProps> = ({
  initialValues = [],
  onSave,
  name,
}) => {
  const [selectedValues, setSelectedValues] = useState<Platform[]>(initialValues);

  const handleSave = () => {
    onSave(selectedValues);
  };

  const options = Object.values(Platform)
    .filter(platform => platform !== Platform.UNKNOWN)
    .map(platform => ({
      id: platform,
      label: platformToString(platform) || "",
    }));

  return (
    <div css={containerStyles}>
      <MultiSelect
        values={selectedValues}
        onChange={(values) => setSelectedValues(values as Platform[])}
        options={options}
        name={name}
      />
      <Button 
        onClick={handleSave}
        variant="widget"
        shape="circle"
      >
        <CheckIcon />
      </Button>
    </div>
  );
};

export default PlatformMultiSelect;
