/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { Colours } from '../Constants/Colours';
import { Text } from '../Constants/Text';
import { ReactComponent as ChevronDownIcon } from '../icons/Down-arrow.svg';
import Button from './Button';

type DropdownProps = {
  trigger: React.ReactNode;
  items: {
    id: string;
    label: string;
    onClick: () => void;
  }[];
  variant?: 'text' | 'button';
  className?: string;
};

const styles = {
  container: css`
    position: relative;
  `,

  textTrigger: css`
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background: ${Colours.TERTIARY};
    border-radius: 20px;
    cursor: pointer;
    height: 24px;
    gap: 8px;
    font-weight: ${Text.HEADING.weight};
  `,

  dropdown: css`
    position: absolute;
    top: 100%;
    right: 0;
    background: ${Colours.BACKGROUND};
    border-radius: 20px;
    border: 1px solid ${Colours.DIVIDER};
    padding: 0px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    min-width: 150px;
    overflow: hidden;
    
    transform-origin: top;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: scaleY(0);
    
    &.open {
      opacity: 1;
      transform: scaleY(1);
    }
  `,

  item: css`
    padding: 16px 25px;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: 1px solid ${Colours.DIVIDER};
    text-align: left;
    transition: background-color 0.2s ease;

    &:last-child {
      border-bottom: none;
    }
    
    &:hover {
      background: ${Colours.USER};
    }
  `
};

const Dropdown = ({ trigger, items, variant = 'text', className }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleItemClick = (onClick: () => void) => {
    onClick();
    setIsOpen(false);
  };

  const renderTrigger = () => {
    if (variant === 'text') {
      return (
        <div css={styles.textTrigger} onClick={() => setIsOpen(!isOpen)}>
          {trigger}
          <ChevronDownIcon style={{ 
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease'
          }} />
        </div>
      );
    }

    return (
      <Button 
        variant="tertiary" 
        shape="circle" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {trigger}
      </Button>
    );
  };

  return (
    <div css={styles.container} className={className} ref={dropdownRef}>
      {renderTrigger()}
      <div css={styles.dropdown} className={isOpen ? 'open' : ''}>
        {items.map(item => (
          <div
            key={item.id}
            css={styles.item}
            onClick={() => handleItemClick(item.onClick)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
