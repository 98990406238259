/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";

interface RadioSelectProps {
  value: string;
  onChange: (value: string) => void;
  options: { 
    value: string; 
    label: string;
    description?: string;
  }[];
  name: string;
}

const radioGroupStyles = css`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  padding: 25px;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const radioItemStyles = css`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  font-family: inherit;
  font-size: ${Text.BODY.size};
  font-weight: ${Text.BODY.weight};
  border-radius: 4px;
`;

const radioInputStyles = css`
  appearance: none;
  width: 30px;
  height: 30px;
  border: 1px solid ${Colours.TEXT};
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  position: relative;

  &:hover {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: ${Colours.USER};
      border-radius: 50%;
    }
  }

  &:checked {
    border-color: ${Colours.TEXT};
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: ${Colours.PRIMARY};
      border-radius: 50%;
    }
  }
`;

const radioLabelStyles = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  color: ${Colours.SECONDARY};
`;

const RadioSelect: React.FC<RadioSelectProps> = ({
  value,
  onChange,
  options,
  name,
}) => {
  return (
    <div css={radioGroupStyles}>
      {options.map((option) => (
        <label key={option.value} css={radioItemStyles}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
            css={radioInputStyles}
          />
          <div css={radioLabelStyles}>
            {option.label}
            {option.description && (
              <div css={descriptionStyles}>{option.description}</div>
            )}
          </div>
        </label>
      ))}
    </div>
  );
};

export default RadioSelect;
