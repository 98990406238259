/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../../Constants/Colours";
import { Text } from "../../Constants/Text";

interface SuggestedPromptsProps {
  options: {
    text: string;
    icon?: React.ComponentType;
  }[];
  onSelect: (value: string) => void;
}

const containerStyles = css`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const pillStyles = css`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  background-color: ${Colours.BACKGROUND};
  border: 1px solid ${Colours.DIVIDER};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: ${Text.BODY.size};
  font-weight: ${Text.BODY.weight};
  font-family: inherit;
  height: 40px;

  &:hover {
    background-color: ${Colours.USER};
  }
  
  svg {
    path {
      fill: ${Colours.DIVIDER};
    }
  }
`;

const iconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuggestedPrompts: React.FC<SuggestedPromptsProps> = ({
  options,
  onSelect,
}) => {
  return (
    <div css={containerStyles}>
      {options.map((option, index) => (
        <button
          key={`${option.text}-${index}`}
          css={pillStyles}
          onClick={() => onSelect(option.text)}
        >
          {option.icon && (
            <span css={iconStyles}>
              <option.icon />
            </span>
          )}
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default SuggestedPrompts;
