import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Auth from './Components/Auth';
import { AuthProvider, useAuth } from './context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chat from './Chat';
import { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs';
import { Account, AnalyticsUpdateStep, BrainstormQuickIdeasStep, PlanningStep, ProcessType, ShowContentIdeasStep, ShowContentPlanStep, WhatToPostStep } from './Constants/Type';
import { InsightMetric, metaApiUrls } from './utils/metaApi';
import axios from 'axios';
import { fetchState } from './utils/stateManagement';
import Layout from './Layout';
import Ideas from './Ideas';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Plan from './Plan';

// Landing component
const Landing = () => (
  <div>
    <h1>Flynn - Coming Soon</h1>
  </div>
);

// New Login component
const Login = () => (
  <div>
    <h1>Login to Flynn</h1>
    <Auth />
  </div>
);

// Protected route component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const AppContent = () => {
  const { isAuthenticated, facebookAccessToken, user, userState, updateUserState, userStateLoading } = useAuth();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string>("");

  const fetchInsights = async (since: Date, until: Date, period: 'day' | 'week' | 'month') => {
    const selectedAccount = accounts.find(
      (account) => account.id === selectedAccountId,
    );
    if (!selectedAccount || !selectedAccount.instagram_business_account) {
      toast.error(
        "No Instagram Business Account was found for the selected account.",
      );
      return;
    }
  
    const instagramBusinessAccountId =
      selectedAccount.instagram_business_account.id;
  
    try {
      const insightsUrl = metaApiUrls.getInsightsUrl({
        igUserId: instagramBusinessAccountId,
        metrics: [InsightMetric.IMPRESSIONS],
        since,
        until,
        period,
      });
      const response = await axios.get(insightsUrl, {
        params: { access_token: facebookAccessToken },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching insights:", error);
    }
  }

  const functionCallHandler = async (call: RequiredActionFunctionToolCall) => {
    if (call?.function?.name === "get_instagram_insights") {
      const args = JSON.parse(call.function.arguments);
      const since = new Date(args.since);
      const until = new Date(args.until);
      const period = args.timePeriod;

      try {
        const data = await fetchInsights(since, until, period);
        return JSON.stringify(data);
      } catch (error) {
        toast.error("Error fetching insights from Instagram");
        console.error("Error fetching insights:", error);
      }
    } else if (call?.function?.name === "get_user_state") {
      try {
        const state = await fetchState(user);
        return JSON.stringify(state);
      } catch (error) {
        toast.error("Error fetching user state");
        console.error("Error fetching user state:", error);
        return JSON.stringify({ error: "Failed to fetch user state" });
      }
    } else if (call?.function?.name === "display_analytics_update") {
      await updateUserState({
        process: {
          type: ProcessType.ANALYTICS_UPDATE,
          step: AnalyticsUpdateStep.FOLLOWERS_INCREASE_L7,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE ANALYTICS UPDATE WILL NOW BE DISPLAYED." });
    } else if (call?.function?.name === "create_content_plan") {
      await updateUserState({
        process: {
          type: ProcessType.PLANNING,
          step: PlanningStep.SELECT_DAYS,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE CONTENT PLAN WILL NOW BE CREATED." });
    } else if (call?.function?.name === "what_to_post") {
      await updateUserState({
        process: {
          type: ProcessType.WHAT_TO_POST,
          step: WhatToPostStep.JUNCTION,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE UI WILL NOW BE DISPLAYED." });
    } else if (call?.function?.name === "show_content_plan") {
      await updateUserState({
        process: {
          type: ProcessType.SHOW_CONTENT_PLAN,
          step: ShowContentPlanStep.SHOW_CONTENT_PLAN,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE UI WILL NOW BE DISPLAYED." });
    } else if (call?.function?.name === "create_content_ideas") {
      await updateUserState({
        process: {
          type: ProcessType.BRAINSTORM_CONTENT_IDEAS,
          step: BrainstormQuickIdeasStep.CONTENT_FORMATS,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE UI WILL NOW BE DISPLAYED." });
    } else if (call?.function?.name === "show_content_ideas") {
      await updateUserState({
        process: {
          type: ProcessType.SHOW_CONTENT_IDEAS,
          step: ShowContentIdeasStep.SHOW_CONTENT_IDEAS,
        },
      });

      return JSON.stringify({ instructions_to_system: "DO NOT RESPOND TO THE USER. THE UI WILL NOW BE DISPLAYED." });
    }

    return JSON.stringify({error: "Unknown function call"});
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/chat" /> : <Landing />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/chat" /> : <Login />} />
          <Route path="/chat" element={
            <ProtectedRoute>
              <Layout>
                <Chat userState={userState} functionCallHandler={functionCallHandler} accounts={accounts} setAccounts={setAccounts} selectedAccountId={selectedAccountId} setSelectedAccountId={setSelectedAccountId} userStateLoading={userStateLoading} />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/ideas" element={
            <ProtectedRoute>
              <Layout>
                <Ideas />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/plan" element={
            <ProtectedRoute>
              <Layout>
                <Plan />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/analytics" element={
            <ProtectedRoute>
              <Layout>
                <div>
                  <h1>Analytics</h1>
                </div>
              </Layout>
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
};

const queryClient = new QueryClient();

function AppRouter() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppContent />
        <ToastContainer />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default AppRouter;
