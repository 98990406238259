/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { css, keyframes } from "@emotion/react";
import { AssistantStream } from "openai/lib/AssistantStream";
import Markdown from "react-markdown";
// @ts-expect-error - no types for this yet
import { AssistantStreamEvent } from "openai/resources/beta/assistants/assistants";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";
import { AnnotationDelta, TextDelta } from "openai/resources/beta/threads/messages";
import { useAuth } from "./context/AuthContext";
import { Account, UserState, OnboardingStep, ProcessType, Stage, AnalyticsUpdateStep, PlanningStep, NoneStep, WhatToPostStep, ShowContentPlanStep, BrainstormQuickIdeasStep, ContentType, ShowContentIdeasStep } from "./Constants/Type";
import axios from "axios";
import { toast } from "react-toastify";
import { metaApiUrls } from "./utils/metaApi";
import { getConversationStep } from "./utils/conversationManager";
import { ConfirmationFlow } from "./utils/confirmationFlow";
import WidgetMessage from "./Components/ChatWidgets/WidgetMessage";
import { WidgetProps, isAnalyticsChartWidget, isContentTypeRadioSelectWidget, isDaysMultiSelectWidget, isFormatMultiSelectWidget, isGoalsMultiSelectWidget, isIdeaListWidget, isIdeaMultiSelectWidget, isPlatformMultiSelectWidget, isStageSelectWidget, isSuggestedPromptsWidget, isToneSliderWidget, isWeeklyPlanTimelineWidget } from './Constants/WidgetTypes';
import { useAnalytics } from "./hooks/useAnalytics";
import { useCompletion } from "./hooks/useCompletion";
import { Colours } from "./Constants/Colours";
import { ReactComponent as SendIcon } from './icons/Send.svg';
import { ReactComponent as SettingsIcon } from './icons/Settings.svg';
import { ReactComponent as ScrollDownIcon } from './icons/Scroll-down.svg';
import Button from "./Components/Button";
import Dropdown from './Components/Dropdown';
import LoadingIndicator from "./Components/LoadingIndicator";

type MessageProps = {
  role: "user" | "assistant" | "widget" | "system";
  text: string;
  widget?: WidgetProps;
};

export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://flynn-service-1088866048963.europe-west1.run.app"
    : "http://localhost:8080";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styles
const styles = {
  container: css`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0 0px 0 32px;
    box-sizing: border-box;
  `,

  inputForm: css`
    display: flex;
    width: 100%;
    padding: 10px;
    order: 1;
    position: relative;
    max-width: 800px;
  `,
  inputWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
`,

  input: css`
    flex-grow: 1;
    padding: 20px 24px;
    padding-right: 60px;
    border-radius: 30px;
    border: none;
    font-size: 1em;
    background-color: ${Colours.TERTIARY};
    resize: none;
    height: 20px;
    min-height: 20px;
    max-height: 200px;
    overflow-y: auto;
    font-family: inherit;
    outline: none;

    &:focus {
      outline: 1px solid ${Colours.DIVIDER};
    }
  `,

  button: css`
    padding: 8px 24px;
    background-color: ${Colours.TERTIARY};
    color: ${Colours.TEXT};
    border: none;
    font-size: 1em;
    border-radius: 60px;

    &:disabled {
      background-color: lightgrey;
    }
  `,

  messages: css`
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    order: 2;
    padding-top: 80px;
    white-space: pre-wrap;
    padding-bottom: 100px;
    padding-right: 32px;

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${Colours.USER};
      border-radius: 4px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${Colours.USER} transparent;
  `,

  userMessage: css`
    padding: 20px 25px;
    align-self: flex-end;
    text-align: left;
    border-radius: 30px;
    max-width: 80%;
    overflow-wrap: break-word;
    color: ${Colours.TEXT};
    background-color: ${Colours.USER};
    margin-bottom: 20px;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease forwards;
  `,

  assistantMessage: css`
    padding: 4px 25px;
    align-self: flex-start;
    text-align: left;
    border-radius: 30px;
    max-width: 80%;
    overflow-wrap: break-word;
    color: ${Colours.TEXT};
    background-color: ${Colours.MAIN};
    margin-bottom: 20px;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease forwards;

    img {
      max-width: 100%;
      margin: 8px 0;
      border-radius: 8px;
    }
  `,

  actionButton: css`
    padding: 8px 16px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #d32f2f;
    }
  `,

  sendButton: css`
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  `,

  topBar: css`
    position: absolute;
    margin-top: 20px;
    top: 0px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;
    padding: 0 32px 0 32px;
  `,

  settingsButton: css`
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  dropdownItem: css`
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  `,

  inputContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    position: absolute;
    margin-bottom: 30px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 32px 0 32px;
  `,

  scrollButton: css`
    position: absolute;
    bottom: 90px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;  // Prevent interaction when hidden

    &.visible {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;  // Re-enable interaction when visible
    }
  `,

  systemMessage: css`
    padding: 20px 25px;
    align-self: flex-start;
    text-align: left;
    border-radius: 30px;
    max-width: 80%;
    overflow-wrap: break-word;
    color: ${Colours.SYSTEM};
    margin-bottom: 20px;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  `,
};



const UserMessage = ({ text }: { text: string }) => {
  return <div css={styles.userMessage}>{text}</div>;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <div css={styles.assistantMessage}>
      <Markdown>{text}</Markdown>
    </div>
  );
};

const SystemMessage = ({ text, onResetChat }: { text: string, onResetChat: () => void }) => {
  return (
    <div css={styles.systemMessage}>
      <div>{text}</div>
      <Button variant="secondary" onClick={onResetChat}>
        Restart Chat
      </Button>
    </div>
  );
};

type ChatProps = {
  functionCallHandler?: (
    toolCall: RequiredActionFunctionToolCall
  ) => Promise<string | undefined>;
  accounts: Account[];
  setAccounts: (accounts: Account[]) => void;
  selectedAccountId: string;
  setSelectedAccountId: (accountId: string) => void;
  userState: UserState;
  userStateLoading: boolean;
};

const Message = React.memo(({ 
  role, 
  text, 
  widget, 
  onWidgetChange, 
  onResetChat,
  messageIndex,
  totalMessages
}: MessageProps & { 
  onWidgetChange: (widget: WidgetProps, value: any) => void;
  onResetChat: () => void;
  messageIndex: number;
  totalMessages: number;
}) => {
  const isDisabled = useMemo(() => {
    if (!widget) return false;
    
    // If this isn't the latest widget message, disable it
    const isLatestWidget = messageIndex === totalMessages - 1;
    return !isLatestWidget;
  }, [widget, messageIndex, totalMessages]);

  const widgetProps = useMemo(() => {
    if (!widget) return null;
    
    
    // Explicitly type the onChange handler based on widget type
    if (isAnalyticsChartWidget(widget)) {
      return {
        ...widget,
      }
    }

    if (isStageSelectWidget(widget)) {
      return {
        ...widget,
        onChange: (value: any) => onWidgetChange(widget, value)
      }
    }

    if (isFormatMultiSelectWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isIdeaMultiSelectWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isDaysMultiSelectWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isContentTypeRadioSelectWidget(widget)) {
      return {
        ...widget,
        onChange: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isWeeklyPlanTimelineWidget(widget)) {
      return {
        ...widget,
      }
    }

    if (isToneSliderWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isGoalsMultiSelectWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isSuggestedPromptsWidget(widget)) {
      return {
        ...widget,
        onSelect: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isPlatformMultiSelectWidget(widget)) {
      return {
        ...widget,
        onSave: (value: any) => onWidgetChange(widget, value),
      }
    }

    if (isIdeaListWidget(widget)) {
      return {
        ...widget,
      }
    }
  }, [widget, onWidgetChange]);

  switch (role) {
    case "user":
      return <UserMessage text={text} />;
    case "assistant":
      return <AssistantMessage text={text} />;
    case "widget":
      return widgetProps ? (
        <WidgetMessage 
          text={text}
          widget={widgetProps}
          isDisabled={isDisabled}
        />
      ) : null;
    case "system":
      return <SystemMessage text={text} onResetChat={onResetChat} />;
    default:
      return null;
  }
});

const AccountSelector = ({ 
  accounts,
  selectedAccountId,
  setSelectedAccountId 
}: {
  accounts: Account[];
  selectedAccountId: string;
  setSelectedAccountId: (id: string) => void;
}) => {
  const { loginProvider } = useAuth();

  if (loginProvider === 'google') {
    return null;
  }

  const selectedAccount = accounts.find(acc => acc.id === selectedAccountId);
  
  const items = accounts.map(account => ({
    id: account.id,
    label: account.name,
    onClick: () => setSelectedAccountId(account.id)
  }));

  return (
    <Dropdown
      trigger={<span>{selectedAccount?.name || "Select Account"}</span>}
      items={items}
    />
  );
};

const SettingsMenu = ({
  onResetUserState,
  onResetAnalytics,
  onResetPlanning,
  onLeaveProcess,
  logout
}: {
  onResetUserState: () => void;
  onResetAnalytics: () => void;
  onResetPlanning: () => void;
  onLeaveProcess: () => void;
  logout: () => void;
}) => {
  const items = [
    { id: 'onboarding', label: 'Restart Onboarding', onClick: onResetUserState },
    { id: 'analytics', label: 'Restart Analytics', onClick: onResetAnalytics },
    { id: 'planning', label: 'Restart Planning', onClick: onResetPlanning },
    { id: 'chat', label: 'Normal Chat', onClick: onLeaveProcess },
    { id: 'logout', label: 'Logout', onClick: logout }
  ];

  return (
    <Dropdown
      trigger={<SettingsIcon />}
      variant="button"
      items={items}
    />
  );
};

const Chat = ({
  functionCallHandler = () => Promise.resolve(""),
  accounts,
  setAccounts,
  selectedAccountId,
  setSelectedAccountId,
  userState,
  userStateLoading,
}: ChatProps) => {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [threadId, setThreadId] = useState("");
  const assistantId = "asst_ZAgXyn4s1ixNzEieuk3rzNMY"; // Hardcoded assistant ID
  const currentConfirmationFlow = useRef<ConfirmationFlow | null>(null);
  const { user, logout, facebookAccessToken, updateUserState, loginProvider } = useAuth();
  
  // Memoize the inputs to useAnalytics
  const memoizedAccounts = useMemo(() => accounts, [accounts]);
  const memoizedSelectedAccountId = useMemo(() => selectedAccountId, [selectedAccountId]);
  const memoizedFacebookToken = useMemo(() => facebookAccessToken, [facebookAccessToken]);

  const { fetchFollowersData, fetchReachData, fetchEngagementData } = useAnalytics(
    memoizedAccounts, 
    memoizedSelectedAccountId, 
    memoizedFacebookToken
  );
  const { fetchIdeationFormats, fetchWeeklyPlan, fetchIdeas } = useCompletion();
  const [preloadedData, setPreloadedData] = useState<any>(null);

  // Replace isInitialLoad state with a ref
  const isInitialLoadRef = useRef(true);
  const [hasComponentLoaded, setHasComponentLoaded] = useState(false);


  // Add new state for tracking if we're at bottom
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const autoScrollTimeoutRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Modify scrollToBottom to track auto-scrolling state
  const scrollToBottom = useCallback(() => {
    setIsAutoScrolling(true);
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    
    // Clear any existing timeout
    if (autoScrollTimeoutRef.current) {
      clearTimeout(autoScrollTimeoutRef.current);
    }
    
    // Reset auto-scrolling state after animation completes
    autoScrollTimeoutRef.current = setTimeout(() => {
      setIsAutoScrolling(false);
    }, 300); // Match this with your scroll animation duration
    // todo: the speed isn't actually contant.. so maybe look into this at some point
  }, []);

  // Modify handleScroll to consider auto-scrolling state
  const handleScroll = useCallback(() => {
    if (!messagesContainerRef.current || isAutoScrolling) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 100;
    setShowScrollButton(!isAtBottom);
  }, [isAutoScrolling]);

  const loadingTimerRef = useRef<NodeJS.Timeout>();

  // Create a wrapper function for setIsLoading
  const setLoadingWithDelay = useCallback((loading: boolean) => {
    if (loading) {
      // Clear any existing timer
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
      // Set a new timer for showing loading state
      loadingTimerRef.current = setTimeout(() => {
        setIsLoading(true);
      }, 100);
    } else {
      // Clear timer and hide loading immediately
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
      setIsLoading(false);
    }
  }, []);

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [userInput]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom, isLoading]);

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (autoScrollTimeoutRef.current) {
        clearTimeout(autoScrollTimeoutRef.current);
      }
    };
  }, []);

  // Add effect to set up scroll listener
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Modify the component load effect
  useEffect(() => {
    if (!userStateLoading && !hasComponentLoaded) {
      setHasComponentLoaded(true);
      // Only set isInitialLoadRef if we start in CHAT_WELCOME
      if (userState.process.type === ProcessType.NONE && 
          userState.process.step === NoneStep.CHAT_WELCOME) {
        isInitialLoadRef.current = true;
      }
    }
  }, [userStateLoading, userState.process.type, userState.process.step, hasComponentLoaded]);

  // automatically scroll to bottom of chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const createThread = useCallback(async () => {
    if (!user) return;
    try {
      const token = await user?.getIdToken();
      const res = await fetch(`${API_URL}/api/create-thread`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await res.json();
      console.log("Thread created:", data.threadId);
      setThreadId(data.threadId);
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  }, [user]);

  // New useEffect to create a thread when the component mounts
  useEffect(() => {
    createThread();
  }, [createThread]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(metaApiUrls.getFacebookPages(), {
          params: { access_token: facebookAccessToken },
        });

        const accountsWithInstagram = await Promise.all(
          response.data.data.map(async (account: Account) => {
            const instagramResponse = await axios.get(
              metaApiUrls.getFacebookPageInstagram(account.id),
              {
                params: { access_token: facebookAccessToken },
              }
            );
            return {
              ...account,
              instagram_business_account:
                instagramResponse.data.instagram_business_account,
            };
          }),
        );

        setAccounts(accountsWithInstagram);
        if (accountsWithInstagram.length > 0 && !selectedAccountId) {
          setSelectedAccountId(accountsWithInstagram[0].id);
        }
      } catch (error) {
        toast.error("There was an issue authenticating your Facebook account.");
        console.log("Error fetching accounts:", error);
      }
    };

    if (facebookAccessToken && accounts.length === 0) {  // Only fetch if we don't have accounts
      fetchAccounts();
    }
  }, [facebookAccessToken, accounts.length, setAccounts, selectedAccountId, setSelectedAccountId]);


  // handle displaying messages for current step
  useEffect(() => {
    // Wait for both userState and selectedAccountId to be ready
    if (userStateLoading || (!selectedAccountId && loginProvider === 'facebook')) return;

    const loadMessagesWithData = async () => {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );
      
      if (conversationStep) {
        let newMessages = conversationStep.messages;
        
        if (conversationStep.preload) {
          setLoadingWithDelay(true);
          try {
            let fetchData;
            switch (userState.process.step) {
              case AnalyticsUpdateStep.FOLLOWERS_INCREASE_L7:
                fetchData = fetchFollowersData;
                break;
              case AnalyticsUpdateStep.REACH_INCREASE_L7:
                fetchData = fetchReachData;
                break;
              case AnalyticsUpdateStep.ENGAGEMENT_RATE_L7:
                fetchData = fetchEngagementData;
                break;
              // case PlanningStep.FORMAT:
              //   console.log("Fetching ideation formats");
              //   fetchData = fetchIdeationFormats;
              //   break;
              case BrainstormQuickIdeasStep.IDEAS:
                console.log("Fetching ideas");
                fetchData = fetchIdeas;
                break;
              case PlanningStep.WEEKLY_PLAN:
                console.log("Fetching weekly plan");
                fetchData = fetchWeeklyPlan;
                break;
              case NoneStep.CHAT_WELCOME:
                const isFirstLoad = isInitialLoadRef.current;
                // Set ref to false after use
                isInitialLoadRef.current = false;
                fetchData = () => Promise.resolve({ 
                  isFirstLoad: isFirstLoad,
                  hasContentPlan: userState.planning?.days?.length > 0,
                  previousProcess: userState.previousProcess,
                });
                break;
              case WhatToPostStep.JUNCTION:
                fetchData = () => Promise.resolve({ 
                  hasContentPlan: userState.planning?.days?.length > 0
                });
                break;
              case ShowContentPlanStep.SHOW_CONTENT_PLAN:
                console.log("Fetching weekly plan");
                fetchData = fetchWeeklyPlan;
                break;
              case ShowContentIdeasStep.SHOW_CONTENT_IDEAS:
                console.log("Fetching ideas");
                // todo: fetch ideas using React Query
                fetchData = () => Promise.resolve({
                  ideas: userState.ideation?.ideas?.slice(0, 5)
                });
                break;
            }

            if (fetchData) {
              const newPreloadedData = await conversationStep.preload({
                fetchData
              });
              setPreloadedData(newPreloadedData);

              const updatedStep = getConversationStep(
                userState.process.type,
                userState.process.step,
                userState,
                newPreloadedData
              );
              
              if (updatedStep) {
                newMessages = updatedStep.messages;
              }
            }
          } catch (error) {
            // Add error message to chat
            setMessages(prevMessages => [
              ...prevMessages,
              { 
                role: "system", 
                text: "Sorry, I encountered an error. Please try refreshing, restarting the chat using the button below, or contact support if the problem persists." 
              }
            ]);
            console.error("Error loading data:", error);
            return;
          } finally {
            setLoadingWithDelay(false);
          }
        }
        
        setMessages(prevMessages => {
          const lastMessages = prevMessages.slice(-newMessages.length);
          const newMessagesAsString = JSON.stringify(newMessages);
          const lastMessagesAsString = JSON.stringify(lastMessages);
          
          if (lastMessagesAsString !== newMessagesAsString) {
            return [...prevMessages, ...newMessages];
          }
          return prevMessages;
        });

        // if the step has skip == true, we handle the response immediately after displaying messages
        if (conversationStep.skip) {
          await conversationStep.handleResponse(null, updateUserState);
        }

        setLoadingWithDelay(false);
        console.log("setLoadingWithDelay(false)");
      }
    };

    loadMessagesWithData();
  }, [userState.process.type,
    userState.process.step, 
    userState, 
    userStateLoading, 
    selectedAccountId, 
    accounts, 
    facebookAccessToken, 
    fetchFollowersData, 
    fetchReachData, 
    fetchEngagementData, 
    fetchIdeationFormats, 
    fetchWeeklyPlan, 
    updateUserState,
    fetchIdeas,
    scrollToBottom,
    loginProvider,
    setLoadingWithDelay]
  );

  const sendMessage = useCallback(async (text: string) => {
    if (!threadId || !assistantId) {
      console.error("No thread ID or assistant ID available", { threadId, assistantId });
      return;
    }
    try {
      const token = await user?.getIdToken();

      const response = await fetch(
        `${API_URL}/api/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            content: text,
            assistantId: assistantId,
          }),
        }
      );
      if (response.body === null) {
        console.error("No response body");
        return;
      }
    
      const reader = response.body.getReader();
      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            controller.enqueue(value);
          }
          controller.close();
        },
      });

      const assistantStream = AssistantStream.fromReadableStream(stream);
      handleReadableStream(assistantStream);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }, [threadId, user, assistantId]);

  const submitMessage = useCallback(async (message: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: message },
    ]);

    setInputDisabled(true);
    setLoadingWithDelay(true);

    try {
      if (userState?.process.type === ProcessType.NONE) {
        console.log("Send message threadId:", threadId);
        await sendMessage(message);
      } else {
        const conversationStep = getConversationStep(
          userState.process.type,
          userState.process.step,
          userState,
        );

        if (conversationStep) {
          if (conversationStep.createConfirmationFlow) {
            // Create flow only if it doesn't exist
            const flow = currentConfirmationFlow.current || conversationStep.createConfirmationFlow();
            if (!currentConfirmationFlow.current) {
              currentConfirmationFlow.current = flow;
            }
            
            const result = await flow.processInput(message, user);
            
            setMessages((prevMessages) => [
              ...prevMessages, 
              ...result.messages
            ]);

            if (result.isComplete && result.confirmedValue) {
              await conversationStep.handleResponse(result.confirmedValue, updateUserState);
              console.log("Clearing confirmation flow");
              currentConfirmationFlow.current = null; // Clear the flow when complete
            }
          } else {
            try {
              const aiResponse = await conversationStep.processResponse(message, user);
              
              if (!aiResponse.isValid) {
                const errorMessage = aiResponse.errorMessage || 
                  conversationStep.errorMessages[0];
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { role: "assistant", text: errorMessage },
                ]);
              } else {
                try {
                  await conversationStep.handleResponse(aiResponse.extractedData, updateUserState);
                } catch (error) {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { 
                      role: "system", 
                      text: "Sorry, I encountered an error. Please try refreshing, restarting the chat using the button below, or contact support if the problem persists.",
                    }
                  ]);
                  console.error("Error handling response:", error);
                }
              }
            } catch (error) {
              setMessages((prevMessages) => [
                ...prevMessages,
                { 
                  role: "system", 
                  text: "Sorry, I encountered an error. Please try refreshing, restarting the chat using the button below, or contact support if the problem persists.",
                }
              ]);
              console.error("Error processing response:", error);
            }
          }
        }
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { 
          role: "system", 
          text: "Sorry, I encountered an error. Please try refreshing, restarting the chat using the button below, or contact support if the problem persists.", 
        }
      ]);
      console.error("Error in submitMessage:", error);
    } finally {
      setInputDisabled(false);
    }
  }, [updateUserState, userState, threadId, sendMessage, user, setLoadingWithDelay]);

  const handleSuggestedPromptClick = useCallback(async (prompt: string) => {
    console.log("handleSuggestedPromptClick threadId:", threadId);
    submitMessage(prompt);
  }, [submitMessage, threadId]);

  const handleWidgetChange = useCallback(async (widget: WidgetProps, value: any) => {
    setLoadingWithDelay(true);
    console.log("handleWidgetChange");
    if (isStageSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );
  
      if (conversationStep) {
        // we use handleResponse so we reuse the same logic for text reply and widget reply
        // (user can reply via text in chat, or by interacting with the widget)
        await conversationStep.handleResponse({ stage: value }, updateUserState);
      }
    }

    if (isFormatMultiSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ formats: value }, updateUserState);
      }
    }

    if (isIdeaMultiSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ ideas: value }, updateUserState);
      }
    } 

    if (isDaysMultiSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ days: value }, updateUserState);
      }
    }

    if (isContentTypeRadioSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ contentType: value }, updateUserState);
      }
    }

    if (isToneSliderWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ tone: value }, updateUserState);
      }
    }

    if (isGoalsMultiSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        await conversationStep.handleResponse({ goals: value }, updateUserState);
      }
    }

    if (isSuggestedPromptsWidget(widget)) {
      console.log("widget threadId:", threadId);
      handleSuggestedPromptClick(value);
    }

    if (isPlatformMultiSelectWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );
      if (conversationStep) {
        await conversationStep.handleResponse({ platforms: value }, updateUserState);
      }
    }

    if (isIdeaListWidget(widget)) {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );
      if (conversationStep) {
        await conversationStep.handleResponse({ ideas: value }, updateUserState);
      }
    }
  }, [updateUserState, userState, threadId, handleSuggestedPromptClick]);
  
  
  // Move handleChange outside of Message component and memoize it
  const handleChange = useCallback((widget: WidgetProps, value: any) => {
    handleWidgetChange({ ...widget }, value);
  }, [handleWidgetChange]); 

  const submitActionResult = async (runId: string, toolCallOutputs: any) => {
    try {
      const token = await user?.getIdToken();

      const response = await fetch(
        `${API_URL}/api/threads/${threadId}/submit-tool-outputs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            runId: runId,
            toolCallOutputs: toolCallOutputs,
          }),
        }
      );
      if (response.body === null) {
        // todo: handle error
        return
      }
      const stream = AssistantStream.fromReadableStream(response.body);
      handleReadableStream(stream);
    } catch (error) {
      console.error("Error submitting tool outputs:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    submitMessage(userInput);
    setUserInput("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as any);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    // Reset height to calculate correct scrollHeight
    textarea.style.height = '20px';
    
    // Get the computed styles to account for padding
    const style = window.getComputedStyle(textarea);
    const paddingTop = parseFloat(style.paddingTop);
    const paddingBottom = parseFloat(style.paddingBottom);
    const totalPadding = paddingTop + paddingBottom;

    const scrollHeight = textarea.scrollHeight - totalPadding;
    
    // Only increase height if content exceeds one line
    if (scrollHeight > 20) {
      textarea.style.height = `${Math.min(scrollHeight, 200)}px`;
    } else {
      textarea.style.height = '20px';
    }
  };

  /* Stream Event Handlers */

  // textCreated - create new assistant message
  const handleTextCreated = () => {
    appendMessage("assistant", "");
    setLoadingWithDelay(false);
  };

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta: TextDelta) => {
    if (delta.value != null) {
      appendToLastMessage(delta.value);
    };
    if (delta.annotations != null) {
      annotateLastMessage(delta.annotations);
    }
  };

  // // toolCallCreated - log new tool call
  // const toolCallCreated = (toolCall) => {
  //   if (toolCall.type != "code_interpreter") return;
  //   appendMessage("code", "");
  // };

  // // toolCallDelta - log delta and snapshot for the tool call
  // const toolCallDelta = (delta, snapshot) => {
  //   if (delta.type != "code_interpreter") return;
  //   if (!delta.code_interpreter.input) return;
  //   appendToLastMessage(delta.code_interpreter.input);
  // };

  // handleRequiresAction - handle function call
  const handleRequiresAction = async (
    event: AssistantStreamEvent.ThreadRunRequiresAction
  ) => {
    const runId = event.data.id;
    const toolCalls = event.data.required_action.submit_tool_outputs.tool_calls;
    // loop over tool calls and call function handler
    const toolCallOutputs = await Promise.all(
      toolCalls.map(async (toolCall: RequiredActionFunctionToolCall) => {
        const result = await functionCallHandler(toolCall);
        return { output: result, tool_call_id: toolCall.id };
      })
    );
    setInputDisabled(true);
    submitActionResult(runId, toolCallOutputs);
  };

  // handleRunCompleted - re-enable the input form
  const handleRunCompleted = () => {
    setInputDisabled(false);
    setLoadingWithDelay(false);
  };

  const handleReadableStream = (stream: AssistantStream) => {
    // messages
    stream.on("textCreated", handleTextCreated);
    stream.on("textDelta", handleTextDelta);

    // events without helpers yet (e.g. requires_action and run.done)
    stream.on("event", (event) => {
      if (event.event === "thread.run.requires_action")
        handleRequiresAction(event);
      if (event.event === "thread.run.completed") handleRunCompleted();
    });
  };

  /*
    =======================
    === Utility Helpers ===
    =======================
  */

  const appendToLastMessage = (text: string) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
      };
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  const appendMessage = (role: "user" | "assistant" | "widget", text: string) => {
    setMessages((prevMessages) => [...prevMessages, { role, text }]);
  };

  const resetUserState = () => {
    updateUserState({
      goals: {
        targetPostsPerWeek: 0
      },
      info: {
        name: "",
        verticals: [],
        platforms: [],
        stage: Stage.UNKNOWN,
        tone: 3,
        goals: [],
        categorization: {
          verticals: [],
          targetAudience: [],
          importantDetails: [],
          originalResponse: "",
        },
      },
      previousProcess: null,
      ideation: {
        formats: [],
        ideas: [],
        contentType: ContentType.UNKNOWN,
      },
      planning: {
        days: [],
        weeklyPlan: {
          postIdeas: []
        }
      },
      process: {
        type: ProcessType.ONBOARDING,
        step: OnboardingStep.NAME,
      },
    });
  }

  const leaveProcess = useCallback(() => {
    updateUserState({
      process: {
        type: ProcessType.NONE,
        step: NoneStep.CHAT_WELCOME,
      },
      previousProcess: null,
    });
  }, [updateUserState]);

  const resetAnalytics = () => {
    updateUserState({
      process: {
        type: ProcessType.ANALYTICS_UPDATE,
        step: AnalyticsUpdateStep.FOLLOWERS_INCREASE_L7,
      },
    });
  }

  const resetPlanning = () => {
    updateUserState({
      process: {
        type: ProcessType.PLANNING,
        step: PlanningStep.SELECT_DAYS,
      },
    });
  }

  const annotateLastMessage = (annotations: AnnotationDelta[]) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
      };
      annotations.forEach((annotation) => {
        if (annotation.type === 'file_path' && annotation.text && annotation.file_path?.file_id) {
          updatedLastMessage.text = updatedLastMessage.text.replaceAll(
            annotation.text,
            `/api/files/${annotation.file_path.file_id}`
          );
        }
      });
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
    
  }

  const messagesList = useMemo(() => {
    return (
      <>
        {messages.map((msg, index) => (
          <Message 
            key={`${msg.role}-${index}`}
            role={msg.role} 
            text={msg.text} 
            widget={msg.widget}
            onWidgetChange={handleChange}
            onResetChat={leaveProcess}
            messageIndex={index}
            totalMessages={messages.length}
          />
        ))}
        {<LoadingIndicator isLoading={isLoading} />}
      </>
    );
  }, [messages, handleChange, isLoading, leaveProcess]);

  return (
    <div css={styles.container}>
      <div css={styles.topBar}>
        <AccountSelector
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        />
        <SettingsMenu
          onResetUserState={resetUserState}
          onResetAnalytics={resetAnalytics}
          onResetPlanning={resetPlanning}
          onLeaveProcess={leaveProcess}
          logout={logout}
        />
      </div>
      {threadId && (
        <>
          <div css={styles.messages} ref={messagesContainerRef}>
            {messagesList}
            <div ref={messagesEndRef} />
          </div>
          <div css={styles.inputContainer}>
            <div css={styles.scrollButton} className={showScrollButton ? 'visible' : ''}>
              <Button 
                variant="secondary" 
                onClick={() => {
                  scrollToBottom();
                }} 
                icon={<ScrollDownIcon />}
              >
                Scroll to bottom
              </Button>
            </div>
            <div css={styles.inputWrapper}>
              <form css={styles.inputForm} onSubmit={handleSubmit}>
                <textarea
                  ref={textareaRef}
                  css={styles.input}
                  value={userInput}
                  onChange={(e) => {
                    setUserInput(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder={(!selectedAccountId && loginProvider === 'facebook') ? "Please select an account first" : "Message Flynn"}
                  disabled={inputDisabled || (!selectedAccountId && loginProvider === 'facebook')}
                  rows={1}
                />
                <div css={styles.sendButton}>
                  <Button
                    variant="tertiary"
                    shape="circle"
                    type="submit"
                    disabled={inputDisabled || (!selectedAccountId && loginProvider === 'facebook')}
                  >
                    <SendIcon />
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Chat;
