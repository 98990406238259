import { useState, useCallback } from 'react';
import { InsightMetric, metaApiUrls } from '../utils/metaApi';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Account } from '../Constants/Type';

export type AnalyticsData = {
  followers: {
    data: number[];
    labels: string[];
    percentageChange: number;
  };
  reach: {
    data: number[];
    labels: string[];
    percentageChange: number;
  };
  engagementRate: {
    data: number[];
    labels: string[];
    currentRate: number;
  };
};

const fetchDailyEngagement = async (igUserId: string, date: Date, facebookAccessToken: string) => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  const engagementInsightsUrl = metaApiUrls.getInsightsUrl({
    igUserId,
    metrics: [InsightMetric.ACCOUNTS_ENGAGED],
    since: startOfDay,
    until: endOfDay,
    period: 'day',
    metricType: 'total_value',
  });

  try {
    const response = await axios.get(engagementInsightsUrl, { 
      params: { access_token: facebookAccessToken } 
    });
    
    return {
      value: response.data?.data?.[0]?.total_value?.value ?? 0,
      end_time: endOfDay.toISOString()
    };
  } catch (error) {
    console.error('Error fetching daily engagement:', error);
    return {
      value: 0,
      end_time: endOfDay.toISOString()
    };
  }
};

export function useAnalytics(accounts: Account[], selectedAccountId: string, facebookAccessToken: string | null) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Helper function to validate account
  const validateAccount = useCallback(() => {
    const selectedAccount = accounts.find(account => account.id === selectedAccountId);
    if (!selectedAccount || !selectedAccount.instagram_business_account || !facebookAccessToken) {
      toast.error("No Instagram Business Account was found for the selected account.");
      throw new Error("No Instagram Business Account was found for the selected account.");
    }
    return selectedAccount.instagram_business_account.id;
  }, [accounts, selectedAccountId, facebookAccessToken]);

  const fetchFollowersData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const instagramBusinessAccountId = validateAccount();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 14);

      const followerInsightsUrl = metaApiUrls.getInsightsUrl({
        igUserId: instagramBusinessAccountId,
        metrics: [InsightMetric.FOLLOWER_COUNT],
        since: startDate,
        until: endDate,
        period: 'day',
      });

      const response = await axios.get(followerInsightsUrl, { 
        params: { access_token: facebookAccessToken } 
      });

      const allValues = response.data.values || [];
      const last7DaysValues = allValues.slice(-7);

      return {
        data: last7DaysValues.map((v: any) => v.value) || [0],
        labels: last7DaysValues.map((v: any) => 
          new Date(v.end_time).toLocaleDateString('en-US', { weekday: 'short' })
        ) || ['N/A'],
        percentageChange: last7DaysValues.length ? 
          calculatePercentageChange(allValues) : 0
      };
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch followers data'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [validateAccount, facebookAccessToken]);

  const fetchReachData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const instagramBusinessAccountId = validateAccount();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 14);

      const reachInsightsUrl = metaApiUrls.getInsightsUrl({
        igUserId: instagramBusinessAccountId,
        metrics: [InsightMetric.REACH],
        since: startDate,
        until: endDate,
        period: 'day',
        metricType: 'time_series',
      });

      const response = await axios.get(reachInsightsUrl, { 
        params: { access_token: facebookAccessToken } 
      });

      const allValues = response.data.data?.[0]?.values || [];
      const last7DaysValues = allValues.slice(-7);

      return {
        data: last7DaysValues.map((v: any) => v.value) || [0],
        labels: last7DaysValues.map((v: any) => 
          new Date(v.end_time).toLocaleDateString('en-US', { weekday: 'short' })
        ) || ['N/A'],
        percentageChange: last7DaysValues.length ? 
          calculatePercentageChange(allValues) : 0
      };
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch reach data'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [validateAccount, facebookAccessToken]);

  const fetchEngagementData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const instagramBusinessAccountId = validateAccount();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 7);

      const engagementPromises = [];
      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        engagementPromises.push(
          fetchDailyEngagement(instagramBusinessAccountId, new Date(currentDate), facebookAccessToken!)
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const engagementResponses = await Promise.all(engagementPromises);

      return {
        data: engagementResponses.map(v => v.value),
        labels: engagementResponses.map(v => 
          new Date(v.end_time).toLocaleDateString('en-US', { weekday: 'short' })
        ),
        currentRate: engagementResponses[engagementResponses.length - 1].value
      };
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch engagement data'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [validateAccount, facebookAccessToken]);

  const calculatePercentageChange = (values: any[]) => {
    if (values.length < 14) return 0;
    
    // Split into previous 7 days and last 7 days
    const previous7Days = values.slice(0, 7);
    const last7Days = values.slice(-7);
    
    // Calculate averages
    const previousAverage = previous7Days.reduce((sum, day) => sum + day.value, 0) / 7;
    const lastAverage = last7Days.reduce((sum, day) => sum + day.value, 0) / 7;
    
    // Calculate percentage change between the two averages and round to nearest integer
    return previousAverage === 0 ? 0 : Math.round(((lastAverage - previousAverage) / previousAverage) * 100);
  };

  return {
    fetchFollowersData,
    fetchReachData,
    fetchEngagementData,
    isLoading,
    error
  };
}


// const mockData = {
//     followers: {
//       data: [100, 120, 150, 200, 180, 220, 250],
//       labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//       percentageChange: 15.2
//     },
//     reach: {
//       data: [1000, 1200, 900, 1500, 2000, 1800, 2200],
//       labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//       percentageChange: 22.5
//     },
//     engagementRate: {
//       data: [5.2, 4.8, 6.1, 5.5, 7.2, 6.8, 7.5],
//       labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//       currentRate: 7.5
//     }
//   };
