/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import RadioSelect from "../RadioSelect";
import { ContentType, contentTypeToString } from "../../Constants/Type";

interface ContentTypeRadioSelectProps {
  onChange: (value: ContentType) => void;
}

const ContentTypeRadioSelect: React.FC<ContentTypeRadioSelectProps> = ({ onChange }) => {
  const [value, setValue] = useState<ContentType>(ContentType.UNKNOWN);

  const options = Object.values(ContentType)
    .filter(contentType => contentType !== ContentType.UNKNOWN)
    .map(contentType => ({  
      value: contentType,
      label: contentTypeToString(contentType) || "",
    }));

  return (
    <RadioSelect
      value={value}
      onChange={(newValue) => {
        setValue(newValue as ContentType);
        onChange(newValue as ContentType);
      }}
      options={options}
      name="content-type-radio-select"
    />
  );
};

export default ContentTypeRadioSelect;
