/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import MultiSelect from "../MultiSelect";
import { Idea } from "../../Constants/Type";
import { useCompletion } from "../../hooks/useCompletion";
import Button from "../Button";
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';
import { ReactComponent as RefreshIcon } from '../../icons/Refresh.svg';

interface IdeaMultiSelectProps {
  initialValues?: string[];
  initialOptions: Idea[];
  onSave: (values: Idea[]) => void;   
  name: string;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const buttonContainerStyles = css`
  display: flex;
  gap: 8px;
  align-self: flex-start;
`;

const IdeaMultiSelect: React.FC<IdeaMultiSelectProps> = ({
  initialValues = [],
  initialOptions,
  onSave,
  name,
}) => {
  const { fetchIdeas } = useCompletion();
  const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);
  const [options, setOptions] = useState<Idea[]>(initialOptions);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const selectedOptions = options.filter(opt => selectedValues.includes(opt.id));
      const unselectedCount = options.length - selectedOptions.length;
      
      // Only fetch the number of new options needed
      const { options: newOptions } = await fetchIdeas(unselectedCount);
      
      setOptions([...selectedOptions, ...newOptions]);
    } catch (error) {
      console.error('Error refreshing ideas:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleSave = () => {
    const ideas: Idea[] = selectedValues.map(value => {
      const option = options.find(opt => opt.id === value);
      if (!option) return null;
      return {
        id: option.id,
        title: option.title,
        description: option.description,
        contentType: option.contentType,
      }
    }).filter(idea => idea !== null) as Idea[];

    onSave(ideas);
  };

  return (
    <div css={containerStyles}>
      <MultiSelect
        values={selectedValues}
        onChange={setSelectedValues}
        options={options.map(opt => ({
          id: opt.id,
          label: opt.title || '',
          description: opt.description || '',
        }))}
        name={name}
      />
      <div css={buttonContainerStyles}>
        <Button 
          onClick={handleRefresh}
          disabled={isRefreshing}
          variant="widget"
          shape="circle"
        >
          <RefreshIcon />
        </Button>
        <Button 
          onClick={handleSave}
          variant="widget"
          shape="circle"
        >
          <CheckIcon />
        </Button>
      </div>
    </div>
  );
};

export default IdeaMultiSelect;
