import { FB_API_URL } from '../Constants/Type';

// Types
export enum InsightMetric {
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  FOLLOWER_COUNT = 'follower_count',
  ACCOUNTS_ENGAGED = 'accounts_engaged'
}

interface InsightsParams {
  igUserId: string;
  metrics: InsightMetric[];
  period?: 'day' | 'week' | 'month';
  since?: Date;
  until?: Date;
  metricType?: 'total_value' | 'time_series';
}

// URL Constructors
export const metaApiUrls = {
  // Facebook endpoints
  getFacebookPages: () => `${FB_API_URL}/me/accounts`,
  getFacebookPageInstagram: (pageId: string) => 
    `${FB_API_URL}/${pageId}?fields=instagram_business_account`,

  // Instagram endpoints
  getInsightsUrl: ({
    igUserId,
    metrics,
    period = 'day',
    since = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
    until = new Date(),
    metricType,
  }: InsightsParams): string => {
    const baseUrl = `${FB_API_URL}/${igUserId}/insights`;
    
    const params = new URLSearchParams({
      metric: metrics.join(','),
      period,
      since: Math.floor(since.getTime() / 1000).toString(),
      until: Math.floor(until.getTime() / 1000).toString(),
    });

    if (metricType) {
      params.append('metric_type', metricType);
    }

    return `${baseUrl}?${params.toString()}`;
  },

  // Add more endpoint constructors as needed
  getMediaUrl: (igUserId: string) => 
    `${FB_API_URL}/${igUserId}/media`,
  
  getStoriesUrl: (igUserId: string) =>
    `${FB_API_URL}/${igUserId}/stories`,
};
