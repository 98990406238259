/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";

interface MultiSelectProps {
  values: string[];
  onChange: (values: string[]) => void;
  options: { 
    id: string;
    label: string;
    description?: string;
  }[];
  name: string;
}

const checkboxGroupStyles = css`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  padding: 25px;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const checkboxItemStyles = css`
  display: flex;
  align-items: center;
  gap: 25px;
  cursor: pointer;
  font-size: ${Text.BODY.size};
  font-weight: ${Text.BODY.weight};
  border-radius: 4px;
  font-family: inherit;
`;

const checkboxInputStyles = css`
  appearance: none;
  min-width: 28px;
  min-height: 28px;
  border: 1px solid ${Colours.TEXT};
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  position: relative;

  &:hover {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: ${Colours.USER};
      border-radius: 2px;
    }
  }

  &:checked {
    border-color: ${Colours.TEXT};
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: ${Colours.PRIMARY};
      border-radius: 2px;
    }
  }
`;

const checkboxLabelStyles = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  font-weight: ${Text.HEADING.weight};
  font-size: ${Text.BODY.size};
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  font-weight: ${Text.SMALL_BODY.weight};
  color: ${Colours.SECONDARY};
  text-align: left;
`;

const MultiSelect: React.FC<MultiSelectProps> = ({
  values,
  onChange,
  options,
  name,
}) => {
  const handleChange = (id: string) => {
    if (values.includes(id)) {
      onChange(values.filter((v) => v !== id));
    } else {
      onChange([...values, id]);
    }
  };

  return (
    <div css={checkboxGroupStyles}>
      {options.map((option) => (
        <label key={option.id} css={checkboxItemStyles}>
          <input
            type="checkbox"
            name={name}
            value={option.id}
            checked={values.includes(option.id)}
            onChange={(e) => handleChange(e.target.value)}
            css={checkboxInputStyles}
          />
          <div css={checkboxLabelStyles}>
            {option.label}
            {option.description && (
              <div css={descriptionStyles}>{option.description}</div>
            )}
          </div>
        </label>
      ))}
    </div>
  );
};

export default MultiSelect;
