/** @jsxImportSource @emotion/react */
import React from "react";
import { css, keyframes } from "@emotion/react";
import { Colours } from "../Constants/Colours";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const spinnerStyles = css`
  width: 14px;
  height: 14px;
  border: 3px solid ${Colours.BACKGROUND};
  border-radius: 50%;
  border-top-color: ${Colours.TEXT};
  animation: ${spin} 0.8s linear infinite;
`;

const Spinner: React.FC = () => {
  return <div css={spinnerStyles} />;
};

export default Spinner;
