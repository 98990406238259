/** @jsxImportSource @emotion/react */
import React from "react";
import { Idea } from "../../Constants/Type";
import List from "../List";

interface SimpleIdeaListProps {
  ideas: Idea[];
}

const SimpleIdeaList: React.FC<SimpleIdeaListProps> = ({ ideas }) => {
  console.log("ideas", ideas);
  const listItems = ideas.map(idea => ({
    title: idea.title,
    description: idea.description,
  }));

  return <List items={listItems} />;
};

export default SimpleIdeaList;
