/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, keyframes } from '@emotion/react';
import { Colours } from '../Constants/Colours';

const bounce = keyframes`
  0%, 30%, 100% { 
    transform: translateY(0) scale(1);
  } 
  15% { 
    transform: translateY(-8px) scale(1.2);
  }
  40% {
    transform: translateY(1px) scale(0.9);
  }
  50% {
    transform: translateY(0) scale(1);
  }
`;

const styles = {
  container: css`
    display: flex;
    gap: 4px;
    padding: 25px 25px;
    background-color: ${Colours.BACKGROUND};
    border-radius: 30px;
    align-self: flex-start;
    margin-bottom: 20px;
    min-height: 5px;
  `,
  dot: css`
    width: 5px;
    height: 5px;
    background-color: ${Colours.TEXT};
    border-radius: 50%;
    animation: ${bounce} 1.4s infinite ease-in-out both;
    transform-origin: center;
  `,
};

const LoadingIndicator = ({ isLoading }: { isLoading: boolean }) => (
  <div css={styles.container}>
    {isLoading && (
      <>
        <div css={[styles.dot, css`animation-delay: -0.32s;`]} />
        <div css={[styles.dot, css`animation-delay: -0.16s;`]} />
        <div css={styles.dot} />
      </>
    )}
  </div>
);

export default LoadingIndicator;
