/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import RadioSelect from "../RadioSelect";
import { Stage, stageToString, stageDescription } from "../../Constants/Type";

interface StageRadioSelectProps {
  onChange: (value: Stage) => void;
}

const StageRadioSelect: React.FC<StageRadioSelectProps> = ({ onChange }) => {
  const [value, setValue] = useState<Stage>(Stage.UNKNOWN);

  const options = Object.values(Stage)
    .filter(stage => stage !== Stage.UNKNOWN)
    .map(stage => ({
      value: stage,
      label: stageToString(stage) || "",
      description: stageDescription(stage) || "",
    }));

  return (
    <RadioSelect
      value={value}
      onChange={(newValue) => {
        setValue(newValue as Stage);
        onChange(newValue as Stage);
      }}
      options={options}
      name="creator-stage"
    />
  );
};

export default StageRadioSelect;
