import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ref, get, update, push } from 'firebase/database';
import { database } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { Idea } from '../Constants/Type';
import { useCompletion } from './useCompletion';

export const useIdeasQuery = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { generateCompletion } = useCompletion();

  // Query for fetching ideas
  const {
    data: ideas = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['ideas', user?.uid],
    queryFn: async (): Promise<Idea[]> => {
      if (!user) throw new Error('User not authenticated');
      
      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        return snapshot.val();
      }
      return [];
    },
    enabled: !!user, // Only run query if user exists
  });

  // Mutation for deleting ideas
  const deleteIdea = useMutation({
    mutationFn: async (ideaId: string) => {
      if (!user) throw new Error('User not authenticated');

      const userRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.filter((idea: Idea) => idea.id !== ideaId);
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    // Optimistic update
    onMutate: async (ideaId) => {
      // Cancel any outgoing refetches to avoid overwriting our optimistic update
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });

      // Snapshot the previous value
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);

      // Optimistically update to the new value
      queryClient.setQueryData(['ideas', user?.uid], (old: Idea[] = []) => {
        return old.filter(idea => idea.id !== ideaId);
      });

      // Return context with the previous value
      return { previousIdeas };
    },
    // If mutation fails, use the context we returned above to roll back
    onError: (err, ideaId, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to delete idea');
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Mutation for refreshing a single idea
  const refreshIdea = useMutation({
    mutationFn: async (ideaId: string) => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      // Generate a single new idea
      const prompt = `
      You are tasked to come up with 1 social media content idea for a small business owner.
      We have collected the following information about the business owner:
      ${JSON.stringify(userState)}
      
      Use the information to carefully craft the content idea, taking into account all of the data including platform, content type, target audience, goals etc.
      The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
      Keep the description short and concise, and don't include unnecessary information in the idea description e.g. content type, format, platform, etc.

      Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);

      // Update the specific idea in the database
      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.map((idea: Idea) => 
          idea.id === ideaId 
            ? { 
                ...idea, 
                title: newIdea.title, 
                description: newIdea.description 
              }
            : idea
        );
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    onMutate: async (ideaId) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);
      return { previousIdeas };
    },
    onError: (err, ideaId, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to refresh idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
      console.log('settled in query')
    },
  });

  // Add new mutation for updating ideas
  const updateIdea = useMutation({
    mutationFn: async ({ 
      ideaId, 
      title, 
      description 
    }: { 
      ideaId: string; 
      title: string;
      description: string;
    }) => {
      if (!user) throw new Error('User not authenticated');

      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      if (snapshot.exists()) {
        const currentIdeas = snapshot.val();
        const updatedIdeas = currentIdeas.map((idea: Idea) => 
          idea.id === ideaId 
            ? { ...idea, title, description } 
            : idea
        );
        
        await update(ref(database, `users/${user.uid}/state/ideation`), {
          ideas: updatedIdeas
        });
        
        return updatedIdeas;
      }
      throw new Error('Ideas not found');
    },
    onMutate: async ({ ideaId, title, description }) => {
      await queryClient.cancelQueries({ queryKey: ['ideas', user?.uid] });
      const previousIdeas = queryClient.getQueryData(['ideas', user?.uid]);

      queryClient.setQueryData(['ideas', user?.uid], (old: Idea[] = []) => {
        return old.map(idea => 
          idea.id === ideaId 
            ? { ...idea, title, description }
            : idea
        );
      });

      return { previousIdeas };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['ideas', user?.uid], context?.previousIdeas);
      toast.error('Failed to update idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  // Add new mutation for creating ideas
  const createIdea = useMutation({
    mutationFn: async () => {
      if (!user) throw new Error('User not authenticated');

      // Get current user state
      const userStateRef = ref(database, `users/${user.uid}/state`);
      const userStateSnapshot = await get(userStateRef);
      if (!userStateSnapshot.exists()) throw new Error('User state not found');
      const userState = userStateSnapshot.val();

      const prompt = `
      You are tasked to come up with 1 social media content idea for a small business owner.
      We have collected the following information about the business owner:
      ${JSON.stringify(userState)}
      
      Use the information to carefully craft the content idea, taking into account all of the data including platform, content type, target audience, goals etc.
      The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
      Keep the description short and concise, and don't include unnecessary information in the idea description e.g. content type, format, platform, etc.

      Return the idea in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
      `;

      const completion = await generateCompletion(prompt);
      const { ideas: [newIdea] } = JSON.parse(completion);

      const ideasRef = ref(database, `users/${user.uid}/state/ideation/ideas`);
      const snapshot = await get(ideasRef);
      
      const currentIdeas = snapshot.exists() ? snapshot.val() : [];
      const newId = push(ref(database)).key;
      
      if (!newId) {
        throw new Error('Failed to generate new idea ID');
      }

      const newIdeaWithId = {
        ...newIdea,
        id: newId,
      };
      
      const updatedIdeas = [...currentIdeas, newIdeaWithId];
      
      await update(ref(database, `users/${user.uid}/state/ideation`), {
        ideas: updatedIdeas
      });
      
      return updatedIdeas;
    },
    onError: (err) => {
      toast.error('Failed to create new idea');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ideas', user?.uid] });
    },
  });

  return {
    ideas,
    isLoading,
    error,
    deleteIdea: deleteIdea.mutate,
    isDeleting: deleteIdea.isPending,
    refreshIdea: refreshIdea.mutate,
    isRefreshing: refreshIdea.isPending,
    updateIdea: updateIdea.mutate,
    isUpdating: updateIdea.isPending,
    createIdea: createIdea.mutate,
    isCreating: createIdea.isPending,
  };
};
