import { ProcessType, OnboardingStep, UserState, Stage, Platform, AnalyticsUpdateStep, ChartType, IdeationStep, Format, Idea, PlanningStep, Days, ContentType, Goal, Categorization, NoneStep, WhatToPostStep, ShowContentPlanStep, BrainstormQuickIdeasStep, ShowContentIdeasStep } from '../Constants/Type';
import { User } from 'firebase/auth';
import axios from 'axios';
import { ConfirmationFlow } from './confirmationFlow';
import { WidgetProps } from '../Constants/WidgetTypes';
import { ReactComponent as PlanIcon } from '../icons/Plan.svg';
import { ReactComponent as AnalyticsIcon } from '../icons/Analytics.svg';
import { ReactComponent as IdeaIcon } from '../icons/Idea.svg';
import { push, ref } from 'firebase/database';
import { database } from '../firebase';
import { getPreviousProcessMessage } from './normalChatMessageMap';

type Message = {
  role: 'assistant' | 'user' | 'widget';
  text: string;
  widget?: WidgetProps;
};

export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://flynn-service-1088866048963.europe-west1.run.app"
    : "http://localhost:8080";

type AIResponse = {
  isValid: boolean;
  extractedData?: any;
  errorMessage?: string;
};

type ConversationStep = {
  messages: Message[];
  errorMessages: string[];
  createConfirmationFlow?: () => ConfirmationFlow;
  processResponse: (
    response: string,
    user: User | null
  ) => Promise<AIResponse>;
  handleResponse: (
    extractedData: any,
    updateUserState: (updates: Partial<UserState>) => Promise<void>
  ) => Promise<void>;
  skip?: boolean;
  preload?: (helpers: {
    fetchData: () => Promise<any>;
  }) => Promise<any>;
};

export const createJunction = (question: string, response: string, options: string[]) => {
  return `We asked the user the following question: "${question}"
   The user response is: "${response}"
   If their answer is unrelated to the question, return: {"isValid": false, "errorMessage": "Sorry I don't understand that. Can you try again?"}.
   Otherwise, analyse their response and YOU MUST choose the most suitable option from: ${options.join(", ")}.
   The chosen option MUST be exactly as it appears in the options array e.g. all capitals separated by underscores.
   Return the chosen option in JSON like this: {"isValid": true, "extractedData": "<chosen option>"}.`
}

export const getConversationFlow = (userState: UserState, preloadedData?: any) => ({
  [ProcessType.NONE]: {
    [NoneStep.CHAT_WELCOME]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { 
          role: 'assistant', 
          text: preloadedData.data.isFirstLoad 
            ? "Hi there " + userState.info.name + "! How can I help you today?"
            : (preloadedData.data.previousProcess 
              ? getPreviousProcessMessage(preloadedData.data.previousProcess).replace('[Name]', userState.info.name)
              : "What else can I help you with?")
        },
        {
        role: 'widget',
        widget: { 
          widgetType: 'suggested-prompts', 
          options: [
            { text: preloadedData.data.hasContentPlan ? "Show my content plan" : "Let's make a content plan", icon: PlanIcon }, 
            { text: "Let's talk about analytics", icon: AnalyticsIcon }
          ], 
          onSelect: () => {} 
        }
      }
      ] : [
        { 
          role: 'assistant', 
          text: "Hi there " + userState.info.name + "! How can I help you today? (no data)" 
        },
        {
        role: 'widget',
        widget: { 
          widgetType: 'suggested-prompts', 
          options: [
            { text: "Let's make a content plan", icon: PlanIcon }, 
            { text: "Let's talk about analytics", icon: AnalyticsIcon }
          ], 
          onSelect: () => {} 
        }
        },
    ]
    }
  },
  [ProcessType.ONBOARDING]: {
    [OnboardingStep.NAME]: {
      messages: [
        { role: 'assistant', text: "Hey! I'm Flynn, here to help! What can I call you?" },
      ],
      errorMessages: [
        "I couldn't quite catch your name. Could you please try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `Extract a name from this response. 
           If a valid name is found, return the following JSON with the first letter capitalised (e.g. John): {"isValid": true, "extractedData": {"name": "EXTRACTED_NAME"}}
           If no valid name is found, return: {"isValid": false}
           Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      // createConfirmationFlow: () => new ConfirmationFlow({
      //   messages: {
      //     askConfirmation: (data) => `Is ${data.name} correct?`,
      //     askNewValue: "What should I call you instead?",
      //     error: ["I couldn't quite catch your name. Could you please try again?"]
      //   },
      //   processResponse: async (response, user) => {
      //     const aiResponse = await generateCompletion(
      //       `Extract a name from this response. 
      //        If a valid name is found, return the following JSON with the first letter capitalised (e.g. John): {"isValid": true, "extractedData": {"name": "EXTRACTED_NAME"}}
      //        If no valid name is found, return: {"isValid": false}
      //        Response: ${response}`,
      //       user
      //     );
      //     return JSON.parse(aiResponse);
      //   },
      //   validateConfirmation: async (response, previousValue, user) => {
      //     const aiResponse = await generateCompletion(
      //       `Analyze this response to a name confirmation.
      //        Previous name: "${previousValue.name}"
      //        If it's a confirmation (yes/yeah/correct/etc), return: {"isConfirmed": true}
      //        If it contains a new name (e.g. "no, it's John"), return: {"isConfirmed": false, "newValue": {"name": "NEW_NAME"}}
      //        If it's just a rejection (no/nope/incorrect/etc), return: {"isConfirmed": false}
      //        Response: ${response}`,
      //       user
      //     );
      //     return JSON.parse(aiResponse);
      //   }
      // }),
      handleResponse: async (extractedData: { name: string }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            name: extractedData.name,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.INTRO,
          },
        });
      },
    },
    [OnboardingStep.INTRO]: {
      messages: [
        { role: 'assistant', text: `Nice to meet you ${userState.info.name}!` },
        { role: 'assistant', text: `To help me start, I'm going to ask a few quick things so I can understand how to best support you.` },
        { role: 'widget', widget: { widgetType: 'suggested-prompts', options: [{ text: "Let's do it!" }], onSelect: () => {} } }
      ],
      errorMessages: [
        "I couldn't quite understand your response. Could you please try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We asked the user if they want to continue to the next step. If they do, return JSON: {"isValid": true}. If they don't, return: {"isValid": false}. User response: "${response}"`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { isValid: boolean }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.STAGE,
          },
        });
      },
    },
    [OnboardingStep.STAGE]: {
      messages: [
        {
          role: 'assistant',
          text: "Ok! What level of expertise do you consider yourself to be when it comes to social media marketing?",
        },
        {
          role: 'widget',
          widget: {
            widgetType: 'stage-select',
            onChange: async (stage: Stage) => {
              // This is replaced by the onChange handler in Chat.tsx
              // do nothing
            }
          }
        },
      ],
      errorMessages: [
        "I couldn't quite understand your selection. Could you please try and describe where you are again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We have given the user a list of stages: NEW, BEGINNER, INTERMEDIATE, PRO. Choose a stage best suited to them from their response. If valid, return: {"isValid": true, "extractedData": {"stage": "SELECTED_STAGE"}}. If invalid, return: {"isValid": false, "errorMessage": "I couldn't quite understand your selection. Could you please try and describe where you are again?"}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { stage: string }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            stage: extractedData.stage as Stage,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.PLATFORMS,
          },
        });
      },
    },
    [OnboardingStep.PLATFORMS]: {
      messages: [
        { role: 'assistant', text: 'Next question - What platforms do you create for at the moment?' },
        {
          role: 'widget',
          widget: {
            widgetType: 'platform-multi-select',
            onSave: async (platforms: Platform[]) => {
            }
          }
        },
      ],
      errorMessages: [
        "I couldn't understand your platform selection. Please choose from Instagram, YouTube, or TikTok.",
        "Sorry, I need valid platforms to continue. Can you try again with Instagram, YouTube, or TikTok?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `Extract the platforms from this response. Valid platforms are INSTAGRAM, YOUTUBE, TIKTOK. If valid platforms are found, return them in JSON format: {"isValid": true, "extractedData": {"platforms": ["PLATFORM1", "PLATFORM2"]}}. If no valid platforms are found, return: {"isValid": false, "errorMessage": "I couldn't understand your platform selection. Please choose from Instagram, YouTube, or TikTok."}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { platforms: Platform[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            platforms: extractedData.platforms,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.VERTICALS,
          },  
        });
      },
    },
    [OnboardingStep.VERTICALS]: {
      messages: [
        { role: 'assistant', text: "Great, that helps me know where to focus. Let's talk a bit more about you..." },
        { role: 'assistant', text: 'What is your business or brand? For example, "I make hand drawn illustrations for toddlers and young children". Feel free to write as much as you like here, and we can amend it over time.' },
      ],
      errorMessages: [
        "I couldn't understand your business or brand. Please explain it in more detail.",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We have asked the user to explain what their business or brand is.
          From their response we should infer the following information which will be used to tailor content ideas:
          - verticals: the verticals/categories they are in
          - target audience: the audience they are/should be targeting
          - important details: specific details that they mention about their business or brand e.g. where they are based, their company name, specific products/services they offer, etc.
          - original response: the entire original response from the user
          Return the data in JSON format: {"isValid": true, "extractedData": {"categorization": {"verticals": ["vertical 1", ...], "targetAudience": ["audience 1", ...], "importantDetails": ["important detail 1", ...], "originalResponse": "full original response"}}}
          If the response is not clear, return: {"isValid": false, "errorMessage": "I couldn't understand your business or brand. Please explain it in more detail."}
          Users response: "${response}"`,
          user
        );
        return JSON.parse(aiResponse);
      },
      // createConfirmationFlow: () => new ConfirmationFlow({
      //   messages: {
      //     askConfirmation: (data) => `Is ${data.verticals} correct?`,
      //     askNewValue: "What verticals should I choose instead?",
      //     error: ["I couldn't quite understand your verticals selection. Could you please try again?"]
      //   },
      //   processResponse: async (response, user) => {
      //     const aiResponse = await generateCompletion(
      //       `Extract the verticals from this response. Valid verticals are FITNESS, PHOTOGRAPHY, FOOD.
      //        If valid verticals are found, return the following JSON with the verticals in their valid uppercaseformat (e.g. FITNESS): {"isValid": true, "extractedData": {"verticals": ["VERTICAL1", "VERTICAL2"]}}
      //        If no valid verticals are found, return: {"isValid": false}
      //        Response: ${response}`,
      //       user
      //     );
      //     return JSON.parse(aiResponse);
      //   },
      //   validateConfirmation: async (response, previousValue, user) => {
      //     const aiResponse = await generateCompletion(
      //       `Valid verticals: FITNESS, PHOTOGRAPHY, FOOD.
      //        Verticals suggested to the user: ${previousValue.verticals.join(", ")}

      //        We have given the user a list of suggested verticals and asked them to confirm. Analyze this response to a verticals confirmation.
      //        If it's a confirmation (yes/yeah/correct/etc), return: {"isConfirmed": true}
      //        If it contains adjusted verticals (e.g. "no, it's Fitness and Photography" or "Add Photography as well"), return: {"isConfirmed": false, "newValue": {"verticals": ["NEW_VERTICAL1", "NEW_VERTICAL2"]}}
      //        If it's just a rejection (no/nope/incorrect/etc), return: {"isConfirmed": false}
      //        User response to analyse: ${response}`,
      //       user
      //     );
      //     return JSON.parse(aiResponse);
      //   }
      // }),
      handleResponse: async (extractedData: { categorization: Categorization }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            categorization: extractedData.categorization,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.TONE,
          },
        });
      },
    },
    [OnboardingStep.TONE]: {
      messages: [
        { role: 'assistant', text: "Love it! This will help me come up with relevant ideas for you." },
        {
          role: 'assistant',
          text: 'When you represent your brand, how would you place your tone of voice on the below scale?',
        },
        {
          role: 'widget',
          widget: {
            widgetType: 'tone-slider',
            onSave: async (tone: number) => {
              // This is replaced by the onChange handler in Chat.tsx
              // do nothing
            }
          }
        },
      ],
      errorMessages: [
        "I couldn't quite understand your selection. Could you please try and describe where you are again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We have given the user a list of stages: NEW, BEGINNER, INTERMEDIATE, PRO. Choose a stage best suited to them from their response. If valid, return: {"isValid": true, "extractedData": {"stage": "SELECTED_STAGE"}}. If invalid, return: {"isValid": false, "errorMessage": "I couldn't quite understand your selection. Could you please try and describe where you are again?"}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { tone: number }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            tone: extractedData.tone,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.GOALS,
          },
        });
      },
    },
    [OnboardingStep.GOALS]: {
      messages: [
        { role: 'assistant', text: "Thank you. I'll use that when refining content ideas." },
        { role: 'assistant', text: "Ok last question from me..." },
        {
          role: 'assistant',
          text: 'What is your main goal with social media?',
        },
        {
          role: 'widget',
          widget: {
            widgetType: 'goals-multi-select',
            onSave: async (goals: Goal[]) => {
              // This is replaced by the onChange handler in Chat.tsx
              // do nothing
            }
          }
        },
      ],
      errorMessages: [
        "I couldn't quite understand your selection. Could you please try and describe where you are again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We have given the user a list of goals: ${Object.values(Goal).join(", ")}. Choose a goal best suited to them from their response. If valid, return: {"isValid": true, "extractedData": {"goals": ["GOAL1", "GOAL2"]}}. If invalid, return: {"isValid": false, "errorMessage": "I couldn't quite understand your selection. Could you please try and describe where you are again?"}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { goals: Goal[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            goals: extractedData.goals,
          },
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.GOALS,
          },
        });
      },
    },
  },
  [ProcessType.ANALYTICS_UPDATE]: {
    [AnalyticsUpdateStep.FOLLOWERS_INCREASE_L7]: {
      messages: preloadedData?.data ? [
        { 
          role: 'assistant', 
          text: `Your total followers ${preloadedData.data.percentageChange >= 0 ? 'increased' : 'decreased'} by ${Math.abs(preloadedData.data.percentageChange)}% in the last 7 days` 
        },
        { 
          role: 'widget', 
          widget: { 
            widgetType: 'analytics-chart', 
            chartType: ChartType.FOLLOWERS,
            value: {
              data: preloadedData.data.data,
              labels: preloadedData.data.labels,
            },
            onChange: () => {} 
          } 
        },
        { role: 'assistant', text: 'Shall we move on to look at your reach?' },
        {
          role: 'widget',
          widget: { 
            widgetType: 'suggested-prompts', 
            options: [
              { text: "Yes, let's move on" }, 
            ], 
            onSelect: () => {} 
          }
        },
      ] : [],
      
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We asked the user if they want to continue to the next step. If they do, return JSON: {"isValid": true}. If they don't, return: {"isValid": false}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { followersIncreaseL7: number }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
          },
          process: {
            type: ProcessType.ANALYTICS_UPDATE,
            step: AnalyticsUpdateStep.REACH_INCREASE_L7,
          },
        });
      },
    },
    [AnalyticsUpdateStep.REACH_INCREASE_L7]: {
      messages: preloadedData?.data ? [
        { role: 'assistant', text: `Your total reach ${preloadedData.data.percentageChange >= 0 ? 'increased' : 'decreased'} by ${Math.abs(preloadedData.data.percentageChange)}% in the same period.` },
        { role: 'widget', widget: { widgetType: 'analytics-chart', chartType: ChartType.REACH, value: { data: preloadedData.data.data, labels: preloadedData.data.labels }, onChange: () => {} } },
        { role: 'assistant', text: 'Shall we move on to look at your engagement rate?' },
        {
          role: 'widget',
          widget: { 
            widgetType: 'suggested-prompts', 
            options: [
              { text: "Yes, let's move on" }, 
            ], 
            onSelect: () => {} 
          }
        },
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We asked the user if they want to continue to the next step. If they do, return: {"isValid": true}. If they don't, return: {"isValid": false}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { reachIncreaseL7: number }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
          },
          process: {
            type: ProcessType.ANALYTICS_UPDATE,
            step: AnalyticsUpdateStep.ENGAGEMENT_RATE_L7,
          },
        });
      },
    },
    [AnalyticsUpdateStep.ENGAGEMENT_RATE_L7]: {
      messages: preloadedData?.data ? [
        { role: 'assistant', text: `And here is your engagement rate trend over the past week. Right now it’s at ${preloadedData.data.currentRate}%. We measure this by looking at the percentage of accounts that you reached that engaged with your content.` },
        { role: 'widget', widget: { widgetType: 'analytics-chart', chartType: ChartType.ENGAGEMENT_RATE, value: { data: preloadedData.data.data, labels: preloadedData.data.labels }, onChange: () => {} } },
        { role: 'assistant', text: 'Thats all for now! If you have any other questions, feel free to ask.' },
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { engagementRateL7: 10 } };
      },
      handleResponse: async (extractedData: { engagementRateL7: number }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
          },
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.ANALYTICS_UPDATE,
            step: AnalyticsUpdateStep.ENGAGEMENT_RATE_L7,
          },
        });
      },
      skip: true,
    },
  },
  [ProcessType.IDEATION]: {
    [IdeationStep.IDEAS]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Here are some ideas for each of your formats. Select the ones that you want to save for later!' },
        { role: 'widget', widget: { widgetType: 'format-multi-select', initialValues: [], options: preloadedData.data.options, onSave: () => {}, name: 'idea-select' } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { ideas: [] } };
      },
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        const ideasWithIds = extractedData.ideas.map(idea => {
          const newId = push(ref(database)).key;
          if (!newId) {
            throw new Error('Failed to generate Firebase ID');
          }
          return {
            ...idea,
            id: newId
          };
        });

        await updateUserState({
          ideation: {
            ...userState.ideation,
            ideas: ideasWithIds,
          },
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.IDEATION,
            step: IdeationStep.IDEAS,
          },
        });
      },
    }
  },
  [ProcessType.WHAT_TO_POST]: {
    [WhatToPostStep.JUNCTION]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { 
          role: 'assistant', 
          text: preloadedData.data.hasContentPlan 
            ? "Would you like to view your content plan, or brainstorm some content ideas?"
            : "Do you want to build a regular posting plan, or brainstorm some content ideas?" 
        },
        {
          role: 'widget',
          widget: {
            widgetType: 'suggested-prompts',
            options: [
              { 
                text: preloadedData.data.hasContentPlan ? "Show my content plan" : "Create a content plan", 
                icon: PlanIcon 
              }, 
              { 
                text: "Brainstorm some content ideas", 
                icon: IdeaIcon 
              }
            ],
            onSelect: () => {}
          }
        },
      ] : [],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(createJunction(
          "Do you want to build a regular posting plan, or brainstorm some content ideas?",
          response,
          ["CREATE_CONTENT_PLAN", "BRAINSTORM_CONTENT_IDEAS", "SHOW_CONTENT_PLAN"]
        ),user);
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: string, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        if (extractedData === "CREATE_CONTENT_PLAN") {
          await updateUserState({
            process: {
              type: ProcessType.PLANNING,
              step: PlanningStep.SELECT_DAYS,
            }
          });
        }
        else if (extractedData === "BRAINSTORM_CONTENT_IDEAS") {
          await updateUserState({
            process: {
              type: ProcessType.BRAINSTORM_CONTENT_IDEAS,
              step: BrainstormQuickIdeasStep.CONTENT_FORMATS,
            }
          });
        } 
        else if (extractedData === "SHOW_CONTENT_PLAN") {
          await updateUserState({
            process: {
              type: ProcessType.SHOW_CONTENT_PLAN,
              step: ShowContentPlanStep.SHOW_CONTENT_PLAN,
            }
          });
        }
      },
    }
  },
  [ProcessType.BRAINSTORM_CONTENT_IDEAS]: {
    [BrainstormQuickIdeasStep.CONTENT_FORMATS]: {
      messages: [
        { role: 'assistant', text: 'What content type do you want to come up with ideas for?' },
        { role: 'widget', widget: { widgetType: 'content-type-radio-select', onChange: () => {} } }
      ],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { contentType: response } };
      },
      handleResponse: async (extractedData: { contentType: ContentType }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          ideation: {
            ...userState.ideation,
            contentType: extractedData.contentType,
          },
          process: {
            type: ProcessType.BRAINSTORM_CONTENT_IDEAS,
            step: BrainstormQuickIdeasStep.IDEAS,
          },
        });
      },
    },
    [BrainstormQuickIdeasStep.IDEAS]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Here are some ideas for you to consider, select the ones that you want to save for later!' },
        { role: 'widget', widget: { widgetType: 'idea-multi-select', initialValues: [], options: preloadedData.data.options, onSave: () => {}, name: 'idea-select' } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { ideas: [] } };
      },
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        const ideasWithIds = extractedData.ideas.map(idea => {
          const newId = push(ref(database)).key;
          if (!newId) {
            throw new Error('Failed to generate Firebase ID');
          }
          return {
            ...idea,
            id: newId
          };
        });

        await updateUserState({
          ideation: {
            ...userState.ideation,
            ideas: [...(userState.ideation?.ideas || []), ...ideasWithIds],
          },
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.BRAINSTORM_CONTENT_IDEAS,
            step: BrainstormQuickIdeasStep.IDEAS,
          },
        });
      },
    }
  },
  [ProcessType.SHOW_CONTENT_PLAN]: {
    [ShowContentPlanStep.SHOW_CONTENT_PLAN]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      skip: true,
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Okay, here is the plan for your week ahead!' },
        { role: 'widget', widget: { widgetType: 'weekly-plan-timeline', initialPostIdeas: preloadedData.data.postIdeas } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { formats: [] } };
      },
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.SHOW_CONTENT_PLAN,
            step: ShowContentPlanStep.SHOW_CONTENT_PLAN,
          },
        });
      },
    }
  },
  [ProcessType.PLANNING]: {
    [PlanningStep.SELECT_DAYS]: {
      messages: [
        { role: 'assistant', text: 'Ok, let’s start by building a regular posting plan, and after that we’ll fill it with ideas together!' },
        { role: 'assistant', text: 'Select which days you want to post on.' },
        { role: 'widget', widget: { widgetType: 'days-multi-select', initialValues: [], onSave: () => {}, name: 'day-select' } }
      ],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { days: [] } };
      },
      handleResponse: async (extractedData: { days: Days[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        // if the user already has enough ideas to fill the weekly plan, skip the ideas steps
        const numberOfDays = extractedData.days.length;
        const hasIdeas = userState?.ideation?.ideas?.length > numberOfDays;

        console.log('number of days', numberOfDays);
        console.log('number of ideas', userState?.ideation?.ideas);

        await updateUserState({
          process: {
            type: ProcessType.PLANNING,
            step: hasIdeas ? PlanningStep.WEEKLY_PLAN : PlanningStep.CONTENT_TYPES,
          },
          planning: {
            ...userState.planning,
            days: extractedData.days,
          },
        });
      },
    },
    [PlanningStep.CONTENT_TYPES]: {
      messages: [
        { role: 'assistant', text: 'What content format do you want to come up with ideas for?' },
        { role: 'widget', widget: { widgetType: 'content-type-radio-select', onChange: () => {} } }
      ],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { contentType: response } };
      },
      handleResponse: async (extractedData: { contentType: ContentType }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          ideation: {
            ...userState.ideation,
            contentType: extractedData.contentType,
          },
          process: {
            type: ProcessType.PLANNING,
            step: PlanningStep.IDEAS,
          },
        });
      },
    },
    [PlanningStep.IDEAS]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Here are some ideas for you to consider, select the ones that you like and we\'ll add them to your plan!' },
        { role: 'widget', widget: { widgetType: 'idea-multi-select', initialValues: [], options: preloadedData.data.options, onSave: () => {}, name: 'idea-select' } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { ideas: [] } };
      },
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        const ideasWithIds = extractedData.ideas.map(idea => {
          const newId = push(ref(database)).key;
          if (!newId) {
            throw new Error('Failed to generate Firebase ID');
          }
          return {
            ...idea,
            id: newId
          };
        });

        await updateUserState({
          ideation: {
            ...userState.ideation,
            ideas: [...(userState.ideation?.ideas || []), ...ideasWithIds],
          },
          process: {
            type: ProcessType.PLANNING,
            step: PlanningStep.WEEKLY_PLAN,
          },
        });
      },
    },
    // [PlanningStep.FORMAT]: {
    //   async preload({ fetchData }: { fetchData: () => Promise<any> }) {
    //     const data = await fetchData();
    //     return { data };
    //   },
    //   messages: preloadedData?.data ? [
    //     { role: 'assistant', text: 'Here are some content formats for you to consider, select the ones that you think are best for you.' },
    //     { role: 'widget', widget: { widgetType: 'format-multi-select', initialValues: [], options: preloadedData.data.options, onSave: () => {}, name: 'format-select' } }
    //   ] : [],
    //   errorMessages: [
    //     "Sorry I don't understand that. Can you try again?",
    //   ],
    //   processResponse: async (response: string, user: User | null) => {
    //     return { isValid: true, extractedData: { formats: [] } };
    //   },
    //   handleResponse: async (extractedData: { formats: Format[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
    //     await updateUserState({
    //       ideation: {
    //         ...userState.ideation,
    //         formats: extractedData.formats,
    //       },
    //       process: {
    //         type: ProcessType.PLANNING,
    //         step: PlanningStep.ASK_MOVE_TO_IDEAS,
    //       },
    //     });
    //   },
    // },
    // [PlanningStep.ASK_MOVE_TO_IDEAS]: {
    //   messages: [
    //     { role: 'assistant', text: 'Great! Now i’ll start adding these to your plan, and generating ideas for you.' },
    //     { role: 'assistant', text: 'Want to take a look at your week ahead?' },
    //     { role: 'widget', widget: { widgetType: 'suggested-prompts', options: [{ text: "Yes, let's see." }, { text: "Maybe later." }], onSelect: () => {} } }
    //   ],
    //   errorMessages: [
    //     "Sorry I don't understand that. Can you try again?",
    //   ],
    //   processResponse: async (response: string, user: User | null) => {
    //     const aiResponse = await generateCompletion(
    //       `We asked the user: "Want to take a look at your week ahead?"
    //        The user response is: "${response}"

    //        If their answer is unrelated to the question, return: {"isValid": false, "errorMessage": "Sorry I don't understand that. Can you try again?"}.
    //        Otherwise, from their response, you MUST return the best option from: LOOK_AT_WEEK_AHEAD, SKIP.
    //        Return json: {"isValid": true, "extractedData": "<option>"}.`,
    //       user
    //     );
    //     return JSON.parse(aiResponse);
    //   },
    //   handleResponse: async (extractedData: string, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
    //     if (extractedData === "LOOK_AT_WEEK_AHEAD") {
    //       await updateUserState({
    //         process: {
    //           type: ProcessType.PLANNING,
    //           step: PlanningStep.WEEKLY_PLAN,
    //         }
    //       });
    //     }
    //     else if (extractedData === "SKIP") {
    //       await updateUserState({
    //         process: {
    //           type: ProcessType.NONE,
    //           step: NoneStep.CHAT_WELCOME,
    //         }
    //       });
    //     }
    //   },
    // },
    [PlanningStep.WEEKLY_PLAN]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      skip: true,
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Great we have a plan, here\'s what it looks like for the week ahead!' },
        { role: 'widget', widget: { widgetType: 'weekly-plan-timeline', initialPostIdeas: preloadedData.data.postIdeas } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      processResponse: async (response: string, user: User | null) => {
        return { isValid: true, extractedData: { formats: [] } };
      },
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.PLANNING,
            step: PlanningStep.WEEKLY_PLAN,
          },
        });
      },
    }
  },
  [ProcessType.SHOW_CONTENT_IDEAS]: {
    [ShowContentIdeasStep.CHECK_IDEAS]: {
      messages: [
        { role: 'assistant', text: 'Here are the ideas we have for you to post this week!' },
      ]
    },
    [ShowContentIdeasStep.SHOW_CONTENT_IDEAS]: {
      async preload({ fetchData }: { fetchData: () => Promise<any> }) {
        const data = await fetchData();
        return { data };
      },
      messages: preloadedData?.data ? [
        { role: 'assistant', text: 'Here are your upcoming ideas!' },
        { role: 'widget', widget: { widgetType: 'idea-list', ideas: preloadedData.data.ideas } }
      ] : [],
      errorMessages: [
        "Sorry I don't understand that. Can you try again?",
      ],
      skip: true,
      handleResponse: async (extractedData: { ideas: Idea[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
      await updateUserState({
          process: {
            type: ProcessType.NONE,
            step: NoneStep.CHAT_WELCOME,
          },
          previousProcess: {
            type: ProcessType.SHOW_CONTENT_IDEAS,
            step: ShowContentIdeasStep.SHOW_CONTENT_IDEAS,
          },
        });
      },
    },
  }
});

export const getConversationStep = (
  processType: ProcessType,
  step: string | null,
  userState: UserState,
  preloadedData?: any
): ConversationStep | undefined => {
  const flow = getConversationFlow(userState, preloadedData);
  if (processType === ProcessType.NONE) {
    return flow[ProcessType.NONE]?.[step as NoneStep] as ConversationStep;
  }
  if (processType === ProcessType.ANALYTICS_UPDATE) {
    return flow[ProcessType.ANALYTICS_UPDATE]?.[step as AnalyticsUpdateStep] as ConversationStep;
  }
  if (processType === ProcessType.IDEATION) {
    return flow[ProcessType.IDEATION]?.[step as IdeationStep] as ConversationStep;
  }
  if (processType === ProcessType.PLANNING) {
    return flow[ProcessType.PLANNING]?.[step as PlanningStep] as ConversationStep;
  }
  if (processType === ProcessType.WHAT_TO_POST) {
    return flow[ProcessType.WHAT_TO_POST]?.[step as WhatToPostStep] as ConversationStep;
  }
  if (processType === ProcessType.SHOW_CONTENT_PLAN) {
    return flow[ProcessType.SHOW_CONTENT_PLAN]?.[step as ShowContentPlanStep] as ConversationStep;
  }
  if (processType === ProcessType.BRAINSTORM_CONTENT_IDEAS) {
    return flow[ProcessType.BRAINSTORM_CONTENT_IDEAS]?.[step as BrainstormQuickIdeasStep] as ConversationStep;
  }
  if (processType === ProcessType.SHOW_CONTENT_IDEAS) {
    return flow[ProcessType.SHOW_CONTENT_IDEAS]?.[step as ShowContentIdeasStep] as ConversationStep;
  }
  return flow[ProcessType.ONBOARDING]?.[step as OnboardingStep] as ConversationStep;
};

async function generateCompletion(prompt: string, user: User | null): Promise<string> {
  try {
    const token = await user?.getIdToken();

    const { data } = await axios.post(`${API_URL}/api/completions`, { prompt }, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return data.completion;
  } catch (error) {
    console.error('Error generating completion:', error);
    return ''; // Return empty string on error, or handle error differently as needed
  }
}
