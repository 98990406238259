/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Event, ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import { css } from '@emotion/react';
import { Colours } from './Constants/Colours';
import { Text } from './Constants/Text';
import { ReactComponent as LeftArrow } from './icons/Left-arrow.svg';
import { ReactComponent as RightArrow } from './icons/Right-arrow.svg';
import Button from './Components/Button';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIdeasQuery } from './hooks/useIdeasQuery';
import { useAuth } from './context/AuthContext';
import { Idea } from './Constants/Type';

const localizer = momentLocalizer(moment);

const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

const toolbarStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const toolbarLabelStyles = css`
  font-size: ${Text.SUBTITLE.size};
  font-weight: ${Text.SUBTITLE.weight};
  color: ${Colours.TEXT};
`;

const toolbarButtonsStyles = css`
  display: flex;
  gap: 8px;
`;

const calendarContainerStyles = css`
  height: calc(100vh - 200px);
  margin: 0 20px;
  padding: 0 20px;
  background: ${Colours.BACKGROUND};
  border-radius: 30px;

  /* Override default calendar styles */
  .rbc-calendar {
    font-family: inherit;
  }

  .rbc-header {
    padding: 12px;
    font-weight: ${Text.SUBTITLE.weight};
    font-size: ${Text.BODY.size};
    border-bottom: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-view {
    border: 1px solid ${Colours.DIVIDER};
    background: ${Colours.BACKGROUND};
    border-radius: 30px;
    overflow: hidden;
  }

  .rbc-date-cell {
    padding: 8px;
    font-size: ${Text.BODY.size};
    text-align: center;

    .rbc-button-link {
      font-family: "Instrument Sans";
      font-size: ${Text.LABEL.size};
    }
  }

  .rbc-off-range-bg {
    background: none;
  }

  .rbc-off-range {
    color: ${Colours.DIVIDER};
  }

  .rbc-day-bg {
    border: none;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${Colours.DIVIDER};
  }

  .rbc-header + .rbc-header {
    border-left: 1px solid ${Colours.DIVIDER};
  }

  .rbc-month-row {
    border: none;
  }

  .rbc-today {
    background-color: ${Colours.HIGHLIGHT};
  }

  .rbc-row-bg {
    height: 100%;
  }

  .rbc-row-content {
    height: 100%;

    .rbc-row {
      &:last-child {
        height: calc(100% - 24px);
      }
    }
  }

  .rbc-row-segment {
    height: 100%;
  }

  .rbc-event {
    height: calc(100% - 20px);
    margin: 0 0;
    background-color: ${Colours.PRIMARY};
    border: none;
    border-radius: 15px;
    color: ${Colours.TEXT};
    font-size: ${Text.SMALL_BODY.size};
    font-weight: ${Text.BODY.weight};
    padding: 8px 8px;
    display: flex;
    align-items: center;
  }

  .rbc-event-content {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 0;
  }

  .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 100%;
    display: block;
    width: 100%;
  }

  .rbc-event.rbc-selected {
    background-color: ${Colours.PRIMARY};
  }

  .rbc-event:focus {
    outline: none;
  }

  .rbc-toolbar {
    margin-bottom: 20px;
    gap: 8px;
  }

  .rbc-toolbar .rbc-btn-group {
    gap: 8px;
    display: flex;
    
    button {
      color: ${Colours.TEXT};
      font-family: 'Instrument Sans';
      font-size: ${Text.BODY.size};
      font-weight: ${Text.HEADING.weight};
      border: 1px solid ${Colours.DIVIDER};
      background: ${Colours.BACKGROUND};
      padding: 10px 24px;
      border-radius: 20px;
      min-width: 120px;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${Colours.USER};
      }

      &.rbc-active {
        background-color: ${Colours.PRIMARY};
        border-color: ${Colours.PRIMARY};
      }
    }

    /* Remove default button group styling */
    & > button:first-of-type:not(:last-of-type) {
      border-radius: 20px;
    }

    & > button:not(:first-of-type):not(:last-of-type) {
      border-radius: 20px;
    }

    & > button:last-of-type:not(:first-of-type) {
      border-radius: 20px;
    }
  }

  .rbc-month-header {
    margin-bottom: 0;
  }
`;

const CustomToolbar: React.FC<ToolbarProps> = (props) => {
  const { onNavigate, label } = props;

  return (
    <div css={toolbarStyles}>
      <span css={toolbarLabelStyles}>{label}</span>
      <div css={toolbarButtonsStyles}>
        <Button
          variant="tertiary"
          onClick={() => onNavigate('TODAY')}
        >
          Today
        </Button>
        <Button
          variant="tertiary"
          shape="circle"
          onClick={() => onNavigate('PREV')}
        >
          <LeftArrow />
        </Button>
        <Button
          variant="tertiary"
          shape="circle"
          onClick={() => onNavigate('NEXT')}
        >
          <RightArrow />
        </Button>
      </div>
    </div>
  );
};

const CustomEvent: React.FC<{ event: Event }> = ({ event }) => {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        font-size: ${Text.SMALL_BODY.size};
        font-weight: ${Text.BODY.weight};
      `}
    >
      {event.title}
    </div>
  );
};

const Plan: React.FC = () => {
  const { ideas, isLoading } = useIdeasQuery();
  const { userState } = useAuth();
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const days = userState.planning?.days;  
    if (!days || !ideas) return;

    // Convert day strings to numbers (0-6, where 0 is Sunday)
    const dayNumbers = days.map(day => {
      const dayIndex = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].indexOf(day);
      return dayIndex;
    }).sort((a, b) => a - b); // Sort days in chronological order

    // Get today's date and day number
    const today = new Date();
    const todayDayNumber = today.getDay();

    // Create events from ideas
    const calendarEvents = ideas.map((idea, index) => {
      // Find the next occurrence of a planned day
      let daysUntilNext = 0;
      let currentDayIndex = index % dayNumbers.length;
      let targetDay = dayNumbers[currentDayIndex];

      // Calculate days until next planned day
      if (targetDay < todayDayNumber) {
        daysUntilNext = 7 - (todayDayNumber - targetDay);
      } else {
        daysUntilNext = targetDay - todayDayNumber;
      }

      // Add extra weeks for subsequent iterations of the same day
      daysUntilNext += Math.floor(index / dayNumbers.length) * 7;

      // Create date for this event
      const eventDate = new Date(today);
      eventDate.setDate(today.getDate() + daysUntilNext);
      
      // Set to midnight for consistent timing
      eventDate.setHours(0, 0, 0, 0);

      return {
        id: idea.id,
        title: idea.title,
        start: eventDate,
        end: eventDate,
      };
    });

    setEvents(calendarEvents);
  }, [ideas, userState.planning?.days]);

  return (
    <div css={containerStyles}>
      <div css={calendarContainerStyles}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          views={['month']}
          defaultView='month'
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent
          }}
        />
      </div>
    </div>
  );
};

export default Plan;
