/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import StageRadioSelect from "./StageRadioSelect";
import { WidgetProps, isAnalyticsChartWidget, isContentTypeRadioSelectWidget, isDaysMultiSelectWidget, isGoalsMultiSelectWidget, isIdeaListWidget, isIdeaMultiSelectWidget, isPlatformMultiSelectWidget, isStageSelectWidget, isSuggestedPromptsWidget, isToneSliderWidget, isWeeklyPlanTimelineWidget } from "../../Constants/WidgetTypes";
import Chart from "./Chart";
import DaysMultiSelect from "./DaysMultiSelect";
import ContentTypeRadioSelect from "./ContentTypeRadioSelect";
import WeeklyPlanTimeline from "./WeeklyPlanTimeline";
import ToneSlider from "./ToneSlider";
import GoalsMultiSelect from "./GoalsMultiSelect";
import SuggestedPrompts from "./SuggestedPrompts";
import PlatformMultiSelect from "./PlatformMultiSelect";
import { Colours } from "../../Constants/Colours";
import IdeaMultiSelect from "./IdeaMultiSelect";
import SimpleIdeaList from "./SimpleIdeaList";

interface WidgetMessageProps {
  text: string;
  widget: WidgetProps;
  isDisabled?: boolean;
}

const WidgetMessage: React.FC<WidgetMessageProps> = ({ text, widget, isDisabled = false}) => {
  const disabledStyles = css`
    pointer-events: ${isDisabled ? 'none' : 'auto'};
  `;

  return (
    <div css={[
      css`
        margin: 8px 0;
        margin-bottom: 25px;
        align-self: flex-start;
        max-width: 80%;
        background-color: ${Colours.BACKGROUND};
        overflow-wrap: break-word;
      `,
      isDisabled && disabledStyles
    ]}>
      {isStageSelectWidget(widget) && (
        <StageRadioSelect 
          onChange={widget.onChange}
        />
      )}
      {isAnalyticsChartWidget(widget) && (
        <Chart 
          data={widget.value.data}
          labels={widget.value.labels}
        />
      )}
      {/* {isFormatMultiSelectWidget(widget) && (
        <FormatMultiSelect
          initialValues={widget.initialValues}
          initialOptions={widget.options}
          onSave={widget.onSave}
          name={widget.name}
        />
      )} */}
      {isDaysMultiSelectWidget(widget) && (
        <DaysMultiSelect
          initialValues={widget.initialValues}
          onSave={widget.onSave}
          name={widget.name}
        />
      )}
      {isContentTypeRadioSelectWidget(widget) && (
        <ContentTypeRadioSelect
          onChange={widget.onChange}
        />
      )} 
      {isWeeklyPlanTimelineWidget(widget) && (
        <WeeklyPlanTimeline initialPostIdeas={widget.initialPostIdeas} />
      )}
      {isToneSliderWidget(widget) && (
        <ToneSlider
          onSave={widget.onSave}
        />
      )}
      {isGoalsMultiSelectWidget(widget) && (
        <GoalsMultiSelect
          onSave={widget.onSave}
          name={widget.name}
        />
      )}
      {isSuggestedPromptsWidget(widget) && (
        <SuggestedPrompts
          onSelect={widget.onSelect}
          options={widget.options}
        />
      )}
      {isPlatformMultiSelectWidget(widget) && (
        <PlatformMultiSelect
          initialValues={widget.initialValues}
          onSave={widget.onSave}
          name={widget.name}
        />
      )}
      {isIdeaMultiSelectWidget(widget) && (
        <IdeaMultiSelect
          initialValues={widget.initialValues}
          initialOptions={widget.options}
          onSave={widget.onSave}
          name={widget.name}
        />
      )} 
      {isIdeaListWidget(widget) && (
        <SimpleIdeaList ideas={widget.ideas} />
      )}
      <div>{text}</div>
    </div>
  );
};

export default WidgetMessage;
