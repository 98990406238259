import { ChartType, ContentType, Days, Format, Goal, Idea, Platform, PostIdea, Stage } from '../Constants/Type';

// Base interface for all widget props
interface BaseWidgetProps {
  text: string;
  widgetType: string;
}

// Specific props for each widget type
interface StageSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'stage-select';
  value: Stage;
  onChange: (stage: Stage) => void;
}

interface AnalyticsChartWidgetProps extends BaseWidgetProps {
  widgetType: 'analytics-chart';
  chartType: ChartType;
  value: {
    data: number[];
    labels: string[];
  };
  onChange: () => void;
}

interface FormatMultiSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'format-multi-select';
  initialValues: string[];
  options: { value: string; label: string; description: string }[];
  onSave: (values: Format[]) => void;
  name: string;
}

interface DaysMultiSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'days-multi-select';
  initialValues: Days[];
  onSave: (values: Days[]) => void;
  name: string;
}

interface ContentTypeRadioSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'content-type-radio-select';
  value: ContentType;
  onChange: (contentType: ContentType) => void;
}

interface WeeklyPlanTimelineWidgetProps extends BaseWidgetProps {
  widgetType: 'weekly-plan-timeline';
  initialPostIdeas: PostIdea[];
}

interface ToneSliderWidgetProps extends BaseWidgetProps {
  widgetType: 'tone-slider';
  onSave: (value: number) => void;
}

interface GoalsMultiSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'goals-multi-select';
  initialValues: Goal[];
  onSave: (values: Goal[]) => void;
  name: string;
}

interface SuggestedPromptsWidgetProps extends BaseWidgetProps {
  widgetType: 'suggested-prompts';
  options: {
    text: string;
    icon?: React.ComponentType;
  }[];
  onSelect: (value: string) => void;
}

interface PlatformMultiSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'platform-multi-select';
  initialValues?: Platform[];
  onSave: (values: Platform[]) => void;
  name: string;
}

interface IdeaMultiSelectWidgetProps extends BaseWidgetProps {
  widgetType: 'idea-multi-select';
  initialValues?: string[];
  options: Idea[];
  onSave: (values: Idea[]) => void;
  name: string;
}

interface IdeaListWidgetProps extends BaseWidgetProps {
  widgetType: 'idea-list';
  ideas: Idea[];
}

// Union type of all widget props
export type WidgetProps = StageSelectWidgetProps | 
AnalyticsChartWidgetProps | 
FormatMultiSelectWidgetProps | 
DaysMultiSelectWidgetProps | 
ContentTypeRadioSelectWidgetProps | 
WeeklyPlanTimelineWidgetProps | 
ToneSliderWidgetProps |
GoalsMultiSelectWidgetProps |
SuggestedPromptsWidgetProps |
PlatformMultiSelectWidgetProps |
IdeaMultiSelectWidgetProps |
IdeaListWidgetProps;

// Type guard to check widget types
export const isStageSelectWidget = (props: WidgetProps): props is StageSelectWidgetProps => {
  return props.widgetType === 'stage-select';
};

export const isAnalyticsChartWidget = (props: WidgetProps): props is AnalyticsChartWidgetProps => {
  return props.widgetType === 'analytics-chart';
};

export const isFormatMultiSelectWidget = (props: WidgetProps): props is FormatMultiSelectWidgetProps => {
  return props.widgetType === 'format-multi-select';
};

export const isDaysMultiSelectWidget = (props: WidgetProps): props is DaysMultiSelectWidgetProps => {
  return props.widgetType === 'days-multi-select';
};

export const isContentTypeRadioSelectWidget = (props: WidgetProps): props is ContentTypeRadioSelectWidgetProps => {
  return props.widgetType === 'content-type-radio-select';
};

export const isWeeklyPlanTimelineWidget = (props: WidgetProps): props is WeeklyPlanTimelineWidgetProps => {
  return props.widgetType === 'weekly-plan-timeline';
};

export const isToneSliderWidget = (props: WidgetProps): props is ToneSliderWidgetProps => {
  return props.widgetType === 'tone-slider';
};

export const isGoalsMultiSelectWidget = (props: WidgetProps): props is GoalsMultiSelectWidgetProps => {
  return props.widgetType === 'goals-multi-select';
};

export const isSuggestedPromptsWidget = (props: WidgetProps): props is SuggestedPromptsWidgetProps => {
  return props.widgetType === 'suggested-prompts';
};

export const isPlatformMultiSelectWidget = (props: WidgetProps): props is PlatformMultiSelectWidgetProps => {
  return props.widgetType === 'platform-multi-select';
};

export const isIdeaMultiSelectWidget = (props: WidgetProps): props is IdeaMultiSelectWidgetProps => {
  return props.widgetType === 'idea-multi-select';
};

export const isIdeaListWidget = (props: WidgetProps): props is IdeaListWidgetProps => {
  return props.widgetType === 'idea-list';
};