/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  steps: number;
  leftLabel?: string;
  rightLabel?: string;
}

const sliderContainerStyles = css`
  padding: 25px;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
  position: relative;
  width: 360px;
`;

const labelsContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

`;

const labelStyles = css`
  font-size: ${Text.BODY.size};
  color: ${Colours.TEXT};
`;

const sliderStyles = css`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: ${Colours.TERTIARY};
  outline: none;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border: 1px solid ${Colours.TEXT};
    border-radius: 50%;
    margin: 0;
    cursor: pointer;
    background: radial-gradient(
      circle at center,
      ${Colours.PRIMARY} 0%,
      ${Colours.PRIMARY} calc(20px / 2),
      ${Colours.BACKGROUND} calc(20px / 2),
      ${Colours.BACKGROUND} 100%
    );
  }

  &::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
    border: 1px solid ${Colours.TEXT};
    background: radial-gradient(
      circle at center,
      ${Colours.PRIMARY} 0%,
      ${Colours.PRIMARY} calc(20px / 2),
      ${Colours.BACKGROUND} calc(20px / 2),
      ${Colours.BACKGROUND} 100%
    );
  }

  &::-webkit-slider-thumb:hover {
    transform: scale(1.1);
  }

  &::-moz-range-thumb:hover {
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const numberContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 0 10px;
`;

const numberStyles = css`
 font-size: ${Text.LABEL.size};
  color: ${Colours.TEXT};
  width: 10px;
  text-align: center;
`;

const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  min,
  max,
  steps,
  leftLabel,
  rightLabel,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    onChange(newValue);
  };

  const step = steps ? (max - min) / steps : 1;

  const numbers = Array.from({ length: steps + 1 }, (_, index) => {
    const value = min + (index * (max - min)) / steps;
    return (
      <span key={index} css={numberStyles}>
        {value}
      </span>
    );
  });

  return (
    <div css={sliderContainerStyles}>
      {(leftLabel || rightLabel) && (
        <div css={labelsContainerStyles}>
          {leftLabel && <span css={labelStyles}>{leftLabel}</span>}
          {rightLabel && <span css={labelStyles}>{rightLabel}</span>}
        </div>
      )}
      <div css={numberContainerStyles}>
        {numbers}
      </div>
      <input
        type="range"
        css={sliderStyles}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
      />
    </div>
  );
};

export default Slider;
