/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";

interface ListItemProps {
  title: string;
  description: string;
}

interface ListProps {
  items: ListItemProps[];
}

const listContainerStyles = css`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const listItemStyles = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colours.DIVIDER};
  }
`;

const titleStyles = css`
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  color: ${Colours.TEXT};
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  color: ${Colours.TEXT};
`;

const List: React.FC<ListProps> = ({ items }) => {
  return (
    <div css={listContainerStyles}>
      {items.map((item, index) => (
        <div key={index} css={listItemStyles}>
          <div css={titleStyles}>{item.title}</div>
          <div css={descriptionStyles}>{item.description}</div>
        </div>
      ))}
    </div>
  );
};

export default List;
