/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Colours } from '../../Constants/Colours';
import { css } from '@emotion/react';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const chartStyles = css`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
  width: 400px;
  height: 200px;
`;

interface ChartProps {
  data?: number[];
  labels?: string[];
  title?: string;
}

const Chart: React.FC<ChartProps> = ({ 
  data = [], 
  labels = [], 
  title = '' 
}) => {
  const chartData = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Dataset',
        data: data,
        borderColor: Colours.MAIN,
        backgroundColor: `${Colours.MAIN}`,
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: Colours.PRIMARY,
        pointBorderColor: Colours.PRIMARY,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: title,
        color: Colours.TEXT,
        font: {
          size: 16,
          weight: 'normal' as const,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: Colours.TEXT,
        },
        ticks: {
          color: Colours.TEXT,
          font: {
            size: 12,
            family: 'Instrument Sans',
          },
        },
      },
      y: {
        border: {
          display: true,
          color: Colours.TEXT,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: Colours.TEXT,
          font: {
            size: 12,
            family: 'Instrument Sans',
          },
        },
      },
    },
  };

  return (
    <div css={chartStyles}>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default Chart;
