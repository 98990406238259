/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import MultiSelect from "../MultiSelect";
import { Goal, goalToString } from "../../Constants/Type";
import Button from "../Button";
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';

interface GoalsMultiSelectProps {
  initialValues?: Goal[];
  onSave: (values: Goal[]) => void;
  name: string;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GoalsMultiSelect: React.FC<GoalsMultiSelectProps> = ({
  initialValues = [],
  onSave,
  name,
}) => {
  const [selectedValues, setSelectedValues] = useState<Goal[]>(initialValues);

  const handleSave = () => {
    onSave(selectedValues);
  };

  const options = Object.values(Goal)
    .filter(goal => goal !== Goal.UNKNOWN)
    .map(goal => ({
      id: goal,
      label: goalToString(goal) || "",
    }));

  return (
    <div css={containerStyles}>
      <MultiSelect
        values={selectedValues}
        onChange={(values) => setSelectedValues(values as Goal[])}
        options={options}
        name={name}
      />
      <Button 
        onClick={handleSave}
        variant="widget"
        shape="circle"
      >
        <CheckIcon />
      </Button>
    </div>
  );
};

export default GoalsMultiSelect;
