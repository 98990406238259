/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css, keyframes } from "@emotion/react";
import { Idea } from "../Constants/Type";
import { Colours } from "../Constants/Colours";
import { Text } from "../Constants/Text";
import { ReactComponent as TrashIcon } from '../icons/Trash.svg';
import { ReactComponent as RefreshIcon } from '../icons/Refresh.svg';
import { ReactComponent as PenIcon } from '../icons/Pen.svg';
import { ReactComponent as CrossIcon } from '../icons/Cross.svg';
import { ReactComponent as CheckIcon } from '../icons/Check.svg';
import { ReactComponent as GenerateTextIcon } from '../icons/GenerateText.svg';
import { ReactComponent as PlusIcon } from '../icons/Plus.svg';

import Spinner from "./Spinner";
import Button from "./Button";

interface IdeaListProps {
  ideas: Idea[];
  onDelete: (ideaId: string) => void;
  isLoading: boolean;
  onRefresh: (ideaId: string) => void;
  refreshingIdeaId?: string;
  onUpdate: (ideaId: string, title: string, description: string) => void;
  onGenerateText: (ideaId: string, title: string, description: string, onSuccess: (newTitle: string, newDescription: string) => void) => void;
  generatingTextIdeaId?: string;
  onCreateIdea: () => void;
}

const ideasContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;
  gap: 12px;
`;

const listContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const addIdeaButtonStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-radius: 30px 30px 0 0;
  width: 100%;
`;

const ideaItemStyles = css`
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  gap: 10px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colours.DIVIDER};
  }
`;

const titleStyles = css`
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  color: ${Colours.TEXT};
`;

const descriptionStyles = css`
  font-size: ${Text.SMALL_BODY.size};
  color: ${Colours.TEXT};
`;

const buttonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${Colours.TEXT};
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const buttonContainerStyles = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  justify-content: flex-end;
`;

const disabledStyles = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

const textAreaStyles = css`
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  font-size: ${Text.SMALL_BODY.size};
  font-family: inherit;
  resize: vertical;
  background-color: ${Colours.USER};
  border: none;
  padding: 20px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const titleInputStyles = css`
  width: 100%;
  font-size: ${Text.HEADING.size};
  font-weight: ${Text.HEADING.weight};
  font-family: inherit;
  background-color: ${Colours.USER};
  border: none;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${Colours.DIVIDER};
  }
`;

const IdeaList: React.FC<IdeaListProps> = ({ 
  ideas, 
  onDelete, 
  isLoading, 
  onRefresh,
  refreshingIdeaId,
  onUpdate,
  onGenerateText,
  generatingTextIdeaId,
  onCreateIdea
}) => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');

  const handleEdit = (idea: Idea) => {
    setEditingId(idea.id);
    setEditedTitle(idea.title || '');
    setEditedDescription(idea.description);
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditedTitle('');
    setEditedDescription('');
  };

  const handleSave = (ideaId: string) => {
    onUpdate(ideaId, editedTitle, editedDescription);
    setEditingId(null);
    setEditedTitle('');
    setEditedDescription('');
  };

  const handleGenerateTextClick = (ideaId: string) => {
    onGenerateText(
      ideaId, 
      editedTitle, 
      editedDescription,
      (newTitle, newDescription) => {
        setEditedTitle(newTitle);
        setEditedDescription(newDescription);
      }
    );
  };

  const editingIdea = editingId !== null;

  return (
    <div css={ideasContainerStyles}>
      <div css={addIdeaButtonStyles}>
        <Button 
          variant="secondary" 
          icon={isLoading ? <Spinner /> : <PlusIcon />}
          onClick={onCreateIdea}
          disabled={isLoading || editingIdea}
        >
          {isLoading ? "" : "Add idea"}
        </Button>
      </div>
      <div css={listContainerStyles}>
        {ideas.map((idea) => {
          const isRefreshing = refreshingIdeaId === idea.id;
          const isEditing = editingId === idea.id;
          const isOtherIdeaBeingEdited = editingId !== null && editingId !== idea.id;
          const isDisabled = isLoading || isOtherIdeaBeingEdited;
          
          return (
            <div 
              key={idea.id} 
              css={[
                ideaItemStyles,
                isRefreshing && css`opacity: 0.7;`
              ]}
            >
              {isEditing ? (
                <>
                  <input
                    css={titleInputStyles}
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    disabled={isLoading}
                  />
                  <textarea
                    css={textAreaStyles}
                    value={editedDescription}
                    onChange={(e) => setEditedDescription(e.target.value)}
                    disabled={isLoading}
                  />
                  <div css={buttonContainerStyles}>
                    <button 
                      css={[buttonStyles, isLoading && disabledStyles]}
                      onClick={() => handleGenerateTextClick(idea.id)}
                      aria-label="Generate text"
                      disabled={generatingTextIdeaId === idea.id}
                    >
                      {generatingTextIdeaId === idea.id ? (
                        <Spinner />
                      ) : (
                        <GenerateTextIcon />
                      )}
                    </button>
                    <button 
                      css={[buttonStyles, isLoading && disabledStyles]}
                      onClick={() => handleCancel()}
                      aria-label="Cancel edit"
                      disabled={isLoading}
                    >
                      <CrossIcon />
                    </button>
                    <button 
                      css={[buttonStyles, isLoading && disabledStyles]}
                      onClick={() => handleSave(idea.id)}
                      aria-label="Save edit"
                      disabled={isLoading}
                    >
                      <CheckIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div css={titleStyles}>{idea.title}</div>
                  <div css={descriptionStyles}>{idea.description}</div>
                  <div css={buttonContainerStyles}>
                    <button 
                      css={[buttonStyles, isDisabled && disabledStyles]}
                      onClick={() => onDelete(idea.id)}
                      aria-label="Delete idea"
                      disabled={isDisabled}
                    >
                      <TrashIcon />
                    </button>
                    {isRefreshing ? (
                      <Spinner />
                    ) : (
                      <button 
                        css={[buttonStyles, isDisabled && disabledStyles]}
                        onClick={() => onRefresh(idea.id)}
                        aria-label="Refresh idea"
                        disabled={isDisabled}
                      >
                        <RefreshIcon />
                      </button>
                    )}
                    <button 
                      css={[buttonStyles, isDisabled && disabledStyles]}
                      onClick={() => handleEdit(idea)}
                      aria-label="Edit idea"
                      disabled={isDisabled}
                    >
                      <PenIcon />
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IdeaList;
