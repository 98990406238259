/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { Colours } from './Constants/Colours';
import { ReactComponent as ChatIcon } from './icons/Chat.svg';
import { ReactComponent as ChatFilledIcon } from './icons/Chat-filled.svg';
import { ReactComponent as IdeasIcon } from './icons/Idea.svg';
import { ReactComponent as IdeasFilledIcon } from './icons/Idea-filled.svg';
import { ReactComponent as PlanIcon } from './icons/Plan.svg';
import { ReactComponent as PlanFilledIcon } from './icons/Plan-filled.svg';
import { ReactComponent as AnalyticsIcon } from './icons/Analytics.svg';
import { ReactComponent as FlynnIcon } from './icons/flynn.svg';
import { ReactComponent as FlynnType } from './icons/flynn-type.svg';
import { ReactComponent as ProfileIcon } from './icons/Profile.svg';
import { Text } from './Constants/Text';
import Button from './Components/Button';
import { ReactComponent as ExpandIcon } from './icons/Expand.svg';

interface LayoutProps {
  children: React.ReactNode;
}

const layoutStyles = css`
  display: flex;
  height: 100vh;
  background-color: ${Colours.BACKGROUND};
`;

const sidebarStyles = css`
  width: 235px;
  max-width: 235px;
  box-sizing: border-box;
  background-color: ${Colours.BACKGROUND};
  border-right: 1px solid ${Colours.DIVIDER};
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;
`;

const sidebarCollapsedStyles = css`
  width: 90px;
`;

const logoStyles = css`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 7px;
  padding-top: 18px;

  img {
    width: 32px;
    height: 32px;
  }
`;

const logoCollapsedStyles = css`
  padding: 7px;
  padding-top: 18px;
`;

const logoIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const logoTextStyles = css`
  padding-top: 5px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
`;

const menuStyles = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
`;

const menuItemStyles = css`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  color: ${Colours.TEXT};
  font-weight: ${Text.SUBTITLE.weight};
  border-radius: 30px;
  height: 60px;
  padding: 20px;
  box-sizing: border-box;
  transition: background-color 0.2s ease;

  .icon {
    display: flex;
    align-items: center;
    min-width: 20px;
    width: 20px;
    justify-content: center;
  }

  &:hover {
    background-color: ${Colours.USER};
  }

  span:not(.icon) {
    transition: opacity 0.2s;
  }
`;

const menuItemActiveStyles = css`
  background-color: ${Colours.HIGHLIGHT};
  color: ${Colours.TEXT};

  .icon {
    color: ${Colours.PRIMARY};
    
    svg {
      path {
        fill: ${Colours.PRIMARY};
      }
    }
  }

  &:hover {
    background-color: ${Colours.HIGHLIGHT};
  }
`;

const profileIconStyles = css`
  svg {
    path {
      fill: ${Colours.BACKGROUND};
    }
  }
`;

const mainContentStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const headerStyles = css`
  box-sizing: border-box;
  height: 70px;
  border-bottom: 1px solid ${Colours.DIVIDER};
`;

const headerContentStyles = css`
  box-sizing: border-box;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const headerTitleStyles = css`
  font-size: ${Text.TITLE.size};
  font-weight: ${Text.TITLE.weight};
`;

const headerActionsStyles = css`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const contentStyles = css`
  flex: 1;
  overflow-y: auto;
`;

const collapseButtonStyles = css`
  margin-top: auto;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  const menuItems = [
    { 
      path: '/chat', 
      icon: <ChatIcon />, 
      filledIcon: <ChatFilledIcon />,
      label: 'Chat' 
    },
    { 
      path: '/ideas', 
      icon: <IdeasIcon />, 
      filledIcon: <IdeasFilledIcon />, 
      label: 'Ideas' 
    },
    { 
      path: '/plan', 
      icon: <PlanIcon />, 
      filledIcon: <PlanFilledIcon />, 
      label: 'Plan' 
    },
    { 
      path: '/analytics', 
      icon: <AnalyticsIcon />, 
      filledIcon: <AnalyticsIcon />, 
      label: 'Analytics' 
    },
  ];

  return (
    <div css={layoutStyles}>
      <aside css={[sidebarStyles, isCollapsed && sidebarCollapsedStyles]}>
        <div css={[logoStyles, isCollapsed && logoCollapsedStyles]}>
          <div css={logoIconStyles}>
            <FlynnIcon />
          </div>
          <div css={logoTextStyles} style={{ opacity: isCollapsed ? 0 : 1 }}>
            {<FlynnType />}
          </div>
        </div>
        
        <nav css={menuStyles}>
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              css={[
                menuItemStyles,
                location.pathname === item.path && menuItemActiveStyles
              ]}
            >
              <span className="icon">
                {location.pathname === item.path ? item.filledIcon : item.icon}
              </span>
              <span style={{ opacity: isCollapsed ? 0 : 1 }}>
                {item.label}
              </span>
            </Link>
          ))}
        </nav>

        <div css={collapseButtonStyles}>
          <Button 
            variant="tertiary"
            shape="circle"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <ExpandIcon style={{ 
              transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s ease'
            }} />
          </Button>
        </div>
      </aside>

      <main css={mainContentStyles}>
        <header css={headerStyles}>
          <div css={headerContentStyles}>
            <div css={headerTitleStyles}>{menuItems.find(item => item.path === location.pathname)?.label}</div>
            <div css={headerActionsStyles}>
                <Button variant="secondary" shape="circle" css={profileIconStyles}>
                    <ProfileIcon />
                </Button>
            </div>
          </div>
        </header>
        <div css={contentStyles}>
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
