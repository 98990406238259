/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import MultiSelect from "../MultiSelect";
import { Days, daysToString } from "../../Constants/Type";
import Button from "../Button";
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';

interface DaysMultiSelectProps {
  initialValues?: Days[];
  onSave: (values: Days[]) => void;
  name: string;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DaysMultiSelect: React.FC<DaysMultiSelectProps> = ({
  initialValues = [],
  onSave,
  name,
}) => {
  const [selectedValues, setSelectedValues] = useState<Days[]>(initialValues);

  const handleSave = () => {
    onSave(selectedValues);
  };

  const options = Object.values(Days)
    .map(day => ({
      id: day,
      label: daysToString(day) || "",
    }));

  return (
    <div css={containerStyles}>
      <MultiSelect
        values={selectedValues}
        onChange={(values) => setSelectedValues(values as Days[])}
        options={options}
        name={name}
      />
      <Button 
        onClick={handleSave}
        variant="widget"
        shape="circle"
      >
        <CheckIcon />
      </Button>
    </div>
  );
};

export default DaysMultiSelect;
