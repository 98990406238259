import { useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../Chat';
import { useAuth } from '../context/AuthContext';
import { PostIdea } from '../Constants/Type';

export const useCompletion = () => {
  const { user, userState } = useAuth();

  const generateCompletion = useCallback(async (
    prompt: string
  ): Promise<string> => {
    try {
      const token = await user?.getIdToken();

      const { data } = await axios.post(
        `${API_URL}/api/completions`, 
        { prompt }, 
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return data.completion;
    } catch (error) {
      console.error('Error generating completion:', error);
      return ''; // Return empty string on error
    }
  }, [user]);

  const fetchIdeationFormats = useCallback(async (count: number = 5): Promise<any> => {
    if (!userState) {
      return {
        options: []
      };
    }

    const prompt = `
    You are tasked to come up with ${count} repeatable social media content formats for a small business owner.
    We have collected the following information about the business owner:
    ${JSON.stringify(userState)}
    
    Use the information to carefully craft the content formats, taking into account all of the data including platform, content type, target audience, goals etc.
    The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
    Keep the descriptions short and concise, we will come up with specific ideas which fit the formats at a later stage.

    Return the formats in a JSON array like this: {formats: [{title: 'format1', description: 'description1'}]}
    `;

    const completion = await generateCompletion(prompt);
    const formats = JSON.parse(completion);

    return {
      options: formats.formats.map((format: any) => ({ 
        value: format.title, 
        label: format.title, 
        description: format.description 
      }))
    };
  }, [generateCompletion, userState]);

  const fetchIdeas = useCallback(async (count: number = 5): Promise<any> => {
    if (!userState) {
      return {
        options: []
      };
    }

    const prompt = `
    You are tasked to come up with ${count} social media content ideas for a small business owner.
    We have collected the following information about the business owner:
    ${JSON.stringify(userState)}
    
    Use the information to carefully craft the content ideas, taking into account all of the data including platform, content type, target audience, goals etc.
    The tone value is a number between 1 and 5, where 1 is playful and 5 is serious - this is very important as it will determine the tone of the content.
    Keep the descriptions short and concise, and don't include unnecessary information in the idea descriptions e.g. content type, format, platform, etc.

    Return the ideas in a JSON array like this: {ideas: [{title: 'idea1', description: 'description1'}]}
    `;

    const completion = await generateCompletion(prompt);
    const ideas = JSON.parse(completion);

    return {
      options: ideas.ideas.map((idea: any) => ({ 
        id: crypto.randomUUID(),
        title: idea.title, 
        description: idea.description,
        contentType: userState?.ideation?.contentType,
      }))
    };
  }, [generateCompletion, userState]);

  const generateWeeklyPlan = useCallback(async (): Promise<any> => {
    console.log('generating weekly plan', userState?.planning?.days, userState?.ideation?.ideas);
    if (!userState.planning?.days || !userState.ideation?.ideas) {
      return {
        postIdeas: []
      };
    }

    const days = userState?.planning.days;
    const ideas = userState?.ideation.ideas;

    // Helper function to get the next date for a given day of the week
    const getNextDayDate = (dayName: string): number => {
      const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
      const today = new Date();
      const targetDay = days.indexOf(dayName);
      const todayDay = today.getDay();
      
      let daysUntilTarget = targetDay - todayDay;
      if (daysUntilTarget <= 0) {
        daysUntilTarget += 7; // Move to next week if day has passed
      }
      
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() + daysUntilTarget);
      return targetDate.getTime();
    };

    console.log(ideas);

    // For each day, assign an idea from the stored ideas
    // Cycle through ideas if we have more days than ideas
    const postIdeas: PostIdea[] = days.map((day: string, index: number) => {
      const idea = ideas[index % ideas.length];
      return {
        title: idea.title,
        description: idea.description,
        contentType: idea.contentType,
        date: getNextDayDate(day).toString(),
        id: idea.id
      };
    });

    return { postIdeas };
  }, [userState?.planning?.days, userState?.ideation?.ideas]);


  const fetchWeeklyPlan = useCallback(async (): Promise<any> => {
    if (!userState) return { postIdeas: [] };

    console.log('fetching ');

    // Generate a new plan
    const { postIdeas } = await generateWeeklyPlan();
    
    return { postIdeas };
  }, [generateWeeklyPlan, userState]);

  return {
    generateCompletion,
    fetchIdeationFormats,
    fetchWeeklyPlan,
    fetchIdeas
  };
};
