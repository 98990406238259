const BaseColours = {
  WHITE: "#FFFCF8",
  BLACK: "#0A0B0D",

  LIGHT_CREAM: "#FAF6EF",
  MEDIUM_CREAM: "#EFE8DD",
  DARK_CREAM: "#D7CEBD",

  LIGHT_YELLOW: "#FFF5DB",
  MEDIUM_YELLOW: "#FFE394",
  DARK_YELLOW: "#FFD969",

  RED: "#CF202F",
};

const Colours = {
  PRIMARY: BaseColours.DARK_YELLOW,
  SECONDARY: BaseColours.BLACK,
  TERTIARY: BaseColours.MEDIUM_CREAM,
  DIVIDER: BaseColours.DARK_CREAM,
  BACKGROUND: BaseColours.WHITE,
  TEXT: BaseColours.BLACK,
  HIGHLIGHT: BaseColours.LIGHT_YELLOW,
  MAIN: BaseColours.MEDIUM_YELLOW,
  USER: BaseColours.LIGHT_CREAM,
  SYSTEM: BaseColours.RED,
};

const Shadows = {
  primary: "2px 2px 10px 0px rgba(0, 0, 0, 0.1)",
  canvas: "7px 7px 15px 0px rgba(0, 0, 0, 0.25)",
};

export { Colours, Shadows };
