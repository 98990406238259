export interface Account {
  id: string;
  name: string;
  instagram_business_account?: {
    id: string;
  };
}

export enum ProcessType {
  NONE = "NONE",
  ONBOARDING = "ONBOARDING",
  ANALYTICS_UPDATE = "ANALYTICS_UPDATE",
  IDEATION = "IDEATION",
  PLANNING = "PLANNING",
  WHAT_TO_POST = "WHAT_TO_POST",
  SHOW_CONTENT_PLAN = "SHOW_CONTENT_PLAN",
  BRAINSTORM_CONTENT_IDEAS = "BRAINSTORM_CONTENT_IDEAS",
  SHOW_CONTENT_IDEAS = "SHOW_CONTENT_IDEAS",
}

export enum ShowContentIdeasStep {
  CHECK_IDEAS = "CHECK_IDEAS",
  SHOW_CONTENT_IDEAS = "SHOW_CONTENT_IDEAS",
}

export enum WhatToPostStep {
  JUNCTION = "JUNCTION",
}

export enum BrainstormQuickIdeasStep {
  CONTENT_FORMATS = "CONTENT_FORMATS",
  IDEAS = "IDEAS",
}

export enum ShowContentPlanStep {
  SHOW_CONTENT_PLAN = "SHOW_CONTENT_PLAN",
}

export enum OnboardingStep {
  NAME = "NAME",
  INTRO = "INTRO",
  STAGE = "STAGE",
  VERTICALS = "VERTICALS",
  PLATFORMS = "PLATFORMS",
  TONE = "TONE",
  GOALS = "GOALS",
}

export enum NoneStep {
  CHAT_WELCOME = "CHAT_WELCOME",
}

export enum AnalyticsUpdateStep {
  FOLLOWERS_INCREASE_L7 = "FOLLOWERS_INCREASE_L7",
  REACH_INCREASE_L7 = "REACH_INCREASE_L7",
  ENGAGEMENT_RATE_L7 = "ENGAGEMENT_RATE_L7",
}

export enum IdeationStep {
  IDEAS = "IDEAS",
}

export enum PlanningStep {
  SELECT_DAYS = "SELECT_DAYS",
  CONTENT_TYPES = "CONTENT_TYPES",
  //FORMAT = "FORMAT",
  IDEAS = "IDEAS",
  //ASK_MOVE_TO_IDEAS = "ASK_MOVE_TO_IDEAS",
  WEEKLY_PLAN = "WEEKLY_PLAN",
}

export enum ChartType {
  FOLLOWERS = "FOLLOWERS",
  REACH = "REACH",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
}

export type ProcessStepMap = {
  [ProcessType.ONBOARDING]: OnboardingStep;
  [ProcessType.NONE]: NoneStep;
  [ProcessType.ANALYTICS_UPDATE]: AnalyticsUpdateStep;
  [ProcessType.IDEATION]: IdeationStep;
  [ProcessType.PLANNING]: PlanningStep;
  [ProcessType.WHAT_TO_POST]: WhatToPostStep;
  [ProcessType.SHOW_CONTENT_PLAN]: ShowContentPlanStep;
  [ProcessType.BRAINSTORM_CONTENT_IDEAS]: BrainstormQuickIdeasStep;
  [ProcessType.SHOW_CONTENT_IDEAS]: ShowContentIdeasStep;
}

export interface Process<T extends ProcessType> {
  type: T;
  step: ProcessStepMap[T];
}

export enum Platform {
  UNKNOWN = "UNKNOWN",
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
}

export enum Vertical {
  FITNESS = "FITNESS",
  PHOTOGRAPHY = "PHOTOGRAPHY",
  FOOD = "FOOD",
}

export enum Stage {
  UNKNOWN = "UNKNOWN",
  NEW = "NEW",
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  PRO = "PRO"
}

export enum Goal {
  UNKNOWN = "UNKNOWN",
  SALES_AND_BRAND_AWARENESS = "SALES_AND_BRAND_AWARENESS",
  COMMUNITY = "COMMUNITY",
  CREATIVE_WORK = "CREATIVE_WORK",
  LIFESTYLE = "LIFESTYLE",
  PROFESSIONAL_NETWORK = "PROFESSIONAL_NETWORK",
}

export enum ContentType {
  UNKNOWN = "UNKNOWN",
  SHORT_FORM_VIDEO = "SHORT_FORM_VIDEO",
  LONG_FORM_VIDEO = "LONG_FORM_VIDEO",
  STORIES = "STORIES",
  IMAGE = "IMAGE",
}

export enum Days {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export const goalToString = (goal: Goal): string | null => {
  switch (goal) {
    case Goal.UNKNOWN: return null;
    case Goal.SALES_AND_BRAND_AWARENESS: return "Grow sales and brand awareness for my business";
    case Goal.COMMUNITY: return "Build a community around my passion or expertise";
    case Goal.CREATIVE_WORK: return "Showcase my creative work and portfolio";
    case Goal.LIFESTYLE: return "Share my lifestyle and personal brand";
    case Goal.PROFESSIONAL_NETWORK: return "Grow my professional network and opportunities";
  }
}

// map each stage to a string - First letter capital - hard code the values

export const stageToString = (stage: Stage): string | null => {
  switch (stage) {
    case Stage.UNKNOWN: return null;
    case Stage.NEW: return "New";
    case Stage.BEGINNER: return "Beginner";
    case Stage.INTERMEDIATE: return "Intermediate";
    case Stage.PRO: return "Pro";
  }
}

export const daysToString = (day: Days): string | null => {
  switch (day) {
    case Days.MONDAY: return "Monday";
    case Days.TUESDAY: return "Tuesday";
    case Days.WEDNESDAY: return "Wednesday";
    case Days.THURSDAY: return "Thursday";
    case Days.FRIDAY: return "Friday";
    case Days.SATURDAY: return "Saturday";
    case Days.SUNDAY: return "Sunday";
  }
}

export const contentTypeToString = (contentType: ContentType): string | null => {
  switch (contentType) {
    case ContentType.UNKNOWN: return null;
    case ContentType.SHORT_FORM_VIDEO: return "Short Form Video (0-60 seconds)";
    case ContentType.LONG_FORM_VIDEO: return "Long Form Video (1-10 minutes)";
    case ContentType.STORIES: return "Stories";
    case ContentType.IMAGE: return "Images";
  }
}

// description for each stage
export const stageDescription = (stage: Stage): string | null => {
  switch (stage) {
    case Stage.UNKNOWN: return null;
    case Stage.NEW: return "You're just starting out and exploring what you can create.";
    case Stage.BEGINNER: return "You've been creating content for a while and are looking to grow your audience.";
    case Stage.INTERMEDIATE: return "You're an established creator with a growing audience.";
    case Stage.PRO: return "You're a professional creator with a large audience.";
  }
}

export const platformToString = (platform: Platform): string | null => {
  switch (platform) {
    case Platform.UNKNOWN: return null;
    case Platform.INSTAGRAM: return "Instagram";
    case Platform.YOUTUBE: return "YouTube";
    case Platform.TIKTOK: return "TikTok";
  }
}

export interface Categorization {
  verticals: string[];
  targetAudience: string[];
  importantDetails: string[];
  originalResponse: string;
}

export interface Info {
  name: string;
  categorization: Categorization;
  verticals: Vertical[];
  platforms: Platform[];
  stage: Stage;
  tone: number;
  goals: Goal[];
}

export interface Goals {
  targetPostsPerWeek: number;
}

export interface PostIdea extends Idea {
  date: string;
}

export interface WeeklyPlan {
  postIdeas: PostIdea[];
}

export interface Planning {
  days: Days[];
  weeklyPlan: WeeklyPlan;
}

export interface Format {
  title: string;
  description: string;
}

export interface Idea {
  id: string;
  description: string;
  title: string;
  contentType: ContentType;
}

export interface Ideation {
  formats: Format[];
  ideas: Idea[];
  contentType: ContentType;
}

export interface UserState {
  goals: Goals;
  process: Process<ProcessType>;
  previousProcess?: Process<ProcessType> | null;
  info: Info;
  ideation: Ideation;
  planning: Planning;
}

export const FB_API_URL = "https://graph.facebook.com/v20.0";