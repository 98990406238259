/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import Slider from "../Slider";
import Button from "../Button";
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';

interface ToneSliderProps {
  initialValue?: number;
  onSave: (value: number) => void;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 250px;
`;

const ToneSlider: React.FC<ToneSliderProps> = ({
  initialValue = 3,
  onSave,
}) => {
  const [value, setValue] = useState<number>(initialValue);

  const handleSave = () => {
    onSave(value);
  };

  return (
    <div css={containerStyles}>
      <Slider
        value={value}
        onChange={setValue}
        min={1}
        max={5}
        steps={4}
        leftLabel="Playful"
        rightLabel="Serious"
      />
      <Button 
        onClick={handleSave}
        variant="widget"
        shape="circle"
      >
        <CheckIcon />
      </Button>
    </div>
  );
};

export default ToneSlider;
