/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { PostIdea, contentTypeToString } from '../../Constants/Type';
import { useCompletion } from '../../hooks/useCompletion';
import { Colours } from '../../Constants/Colours';
import { Text } from '../../Constants/Text';
import Button from '../Button';
import { ReactComponent as RefreshIcon } from '../../icons/Refresh.svg';
import { ReactComponent as InstagramIcon } from '../../icons/Instagram.svg';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const timelineContainer = css`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 25px;
  border-radius: 30px;
  border: 1px solid ${Colours.DIVIDER};
`;

const timelineItem = css`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  position: relative;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    left: 8px;
    top: 17px;
    bottom: -50px;
    width: 1px;
    background: ${Colours.TEXT};
  }
`;

const timelineCircle = css`
  width: 15px;
  height: 15px;
  border: 1px solid ${Colours.TEXT};
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
`;

const timelineContent = css`
  flex: 1;
  text-align: left;
`;

const timelineDate = css`
  font-size: ${Text.BODY.size};
  font-weight: ${Text.HEADING.weight};
  margin: 0 0 20px 0;
  text-align: left;
`;

const timelineTags = css`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const timelineTag = css`
  background: ${Colours.MAIN};
  padding: 7px 20px;
  border-radius: 100px;
  font-size: ${Text.SMALL_BODY.size};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const timelineTagFormat = css`
  background: ${Colours.TERTIARY};
  color: ${Colours.TEXT};
`;

const timelineDescription = css`
  font-size: ${Text.SMALL_BODY.size};
  margin-top: 12px;
  color: ${Colours.TEXT};
  text-align: left;
`;

const timelineTitle = css`
  font-size: ${Text.BODY.size};
  font-weight: ${Text.HEADING.weight};
  margin-bottom: 12px;
  color: ${Colours.TEXT};
  text-align: left;
`;

interface WeeklyPlanTimelineProps {
  initialPostIdeas: PostIdea[];
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};

export const WeeklyPlanTimeline: React.FC<WeeklyPlanTimelineProps> = ({ initialPostIdeas }) => {
  const { fetchWeeklyPlan } = useCompletion();
  const [postIdeas, setPostIdeas] = useState<PostIdea[]>(initialPostIdeas);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const sortedIdeas = [...postIdeas].sort((a, b) => 
    parseInt(a.date) - parseInt(b.date)
  );

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const { postIdeas: newPostIdeas } = await fetchWeeklyPlan();
      setPostIdeas(newPostIdeas);
    } catch (error) {
      console.error('Error refreshing post ideas:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <div css={containerStyles}>
      <div css={timelineContainer}>
      {sortedIdeas.map((idea, index) => (
        <div css={timelineItem} key={index}>
          <div css={timelineCircle} />
          <div css={timelineContent}>
            <div css={timelineDate}>
              {(() => {
                const date = new Date(parseInt(idea.date));
                const day = date.getDate();
                return date.toLocaleDateString('en-US', {
                  weekday: 'long'
                }) + ' ' + day + getOrdinalSuffix(day);
              })()}
            </div>
            <div css={timelineTitle}>{idea.title}</div>
            <div css={timelineTags}>
              <span css={[timelineTag, timelineTagFormat]}>
                <InstagramIcon />
                {contentTypeToString(idea.contentType)}
              </span>
            </div>
            <div css={timelineDescription}>{idea.description}</div>
          </div>
        </div>
        
      ))}
    </div>
    <Button 
          onClick={handleRefresh}
          disabled={isRefreshing}
          variant="widget"
          shape="circle"
        >
          <RefreshIcon />
        </Button>
    </div>
  );
};

export default WeeklyPlanTimeline;
